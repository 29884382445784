import React, { useState } from 'react';
import { UserMenu, useLogout, useTranslate } from 'react-admin';
import { Typography, Box, Avatar, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonIcon from '@mui/icons-material/Person';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ChangePasswordDialog from '../components/UserComponents/ChangePasswordDialog';
import { CustomPasswordIcon } from '../components/pages/CustomIcons';

const StyledUserMenu = styled(UserMenu)(({ theme }) => ({
  '& .MuiPopover-paper': {
    width: '280px',
    overflow: 'hidden',
    borderRadius: '16px',
    boxShadow: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
  }
}));

const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: '64px',
  height: '64px',
  backgroundColor: '#e2e8f0',
  color: '#64748b',
  '& .MuiSvgIcon-root': {
    width: '32px',
    height: '32px',
  }
}));

const MenuButton = styled('button')(({ theme, variant }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '12px 16px',
  border: 'none',
  borderRadius: '8px',
  backgroundColor: variant === 'primary' ? '#47a8b5' : '#f1f5f9',
  color: variant === 'primary' ? '#ffffff' : '#0f172a',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: variant === 'primary' ? '#53c3d2' : '#e2e8f0',
    transform: 'translateY(-1px)',
  },
  '&:active': {
    transform: 'translateY(0)',
  },
  '& .MuiSvgIcon-root': {
    marginRight: '12px',
    fontSize: '20px',
    color: variant === 'primary' ? '#ffffff' : '#64748b',
  }
}));

const MyUserMenu = props => {
  const translate = useTranslate();
  const userName = localStorage.getItem('username') || 'Guest';
  const logout = useLogout();
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  const handlePasswordDialogOpen = () => {
    setPasswordDialogOpen(true);
  };

  const handlePasswordDialogClose = () => {
    setPasswordDialogOpen(false);
  };

  const userRole = localStorage.getItem('role') || 'User';

  return (
    <StyledUserMenu {...props}>
      <Box sx={{ p: 3 }}>
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center"
        >
          <UserAvatar>
            <PersonIcon />
          </UserAvatar>
          <Typography 
            variant="h6" 
            sx={{ 
              mt: 2,
              mb: 1,
              fontWeight: 600,
              color: '#0f172a'
            }}
          >
            {userName}
          </Typography>
          
            <Typography 
            variant="body2" 
            sx={{ 
                color: '#64748b',
                mb: 2
            }}
            >
            {userRole}
            </Typography>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box 
          display="flex" 
          flexDirection="column" 
          gap={1.5}
        >
          <MenuButton
            onClick={handlePasswordDialogOpen}
          >
            <CustomPasswordIcon />
            {translate("resources.users.actions.change_password")}
          </MenuButton>

          <MenuButton
            variant="primary"
            onClick={() => logout()}
          >
            <ExitToAppIcon />
            {translate("ra.auth.logout")}
          </MenuButton>
        </Box>
      </Box>

      <ChangePasswordDialog 
        open={passwordDialogOpen} 
        onClose={handlePasswordDialogClose} 
        userId={localStorage.getItem('userId')}
      />
      {props.children}
    </StyledUserMenu>
  );
};

export default MyUserMenu;