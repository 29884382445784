import React, { useState, useEffect } from 'react';
import { Box, Chip, Typography, Divider, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslate } from 'react-admin';
import { CustomButton } from '../common/CustomButton';
import GroupSelector from '../filters/GroupSelector';
import ConditionalInstallationsSelector from '../filters/ConditionalInstallationSelector';

const UserGroupsAndInstallations = ({
    roleLevel,
    initialGroups = [],
    initialInstallations = [],
    onGroupsChange,
    onInstallationsChange,
    translate: customTranslate
}) => {
    const defaultTranslate = useTranslate();
    const t = customTranslate || defaultTranslate;

    const processInitialGroups = (groups) => {
        return groups.map(group => {
            if (typeof group === 'string') {
                return {
                    id: group,
                    group_name: group,
                    uniqueId: `group-${group}-${Date.now()}`
                };
            }
            return {
                ...group,
                uniqueId: `group-${group.id || 'undefined'}-${Date.now()}`
            };
        });
    };

    const processInitialInstallations = (installations) => {
        return installations.map(installation => {
            if (typeof installation === 'string') {
                return {
                    id: installation,
                    installation_name: installation,
                    uniqueId: `installation-${installation}-${Date.now()}`
                };
            }
            return {
                ...installation,
                uniqueId: `installation-${installation.id || 'undefined'}-${Date.now()}`
            };
        });
    };

    const [userGroups, setUserGroups] = useState(() => processInitialGroups(initialGroups));
    const [userInstallations, setUserInstallations] = useState(() => processInitialInstallations(initialInstallations));
    const [selectedGroupId, setSelectedGroupId] = useState('');
    const [selectedInstallationId, setSelectedInstallationId] = useState('');
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedInstallation, setSelectedInstallation] = useState(null);

    useEffect(() => {
        setUserGroups(processInitialGroups(initialGroups));
    }, [initialGroups]);

    useEffect(() => {
        setUserInstallations(processInitialInstallations(initialInstallations));
    }, [initialInstallations]);

    useEffect(() => {
        if (onGroupsChange) {
            const cleanGroups = userGroups.map(({ uniqueId, ...rest }) => rest);
            onGroupsChange(cleanGroups);
        }
    }, [userGroups, onGroupsChange]);

    useEffect(() => {
        if (onInstallationsChange) {
            const cleanInstallations = userInstallations.map(({ uniqueId, ...rest }) => rest);
            onInstallationsChange(cleanInstallations);
        }
    }, [userInstallations, onInstallationsChange]);

    const handleGroupSelectorChange = (group) => {
        if (!group) return;
    
    
        setSelectedGroup(group);
        setSelectedGroupId(group.id);
    };

    const handleInstallationSelectorChange = (installation) => {
        if (!installation) return;
        setSelectedInstallation(installation);
        setSelectedInstallationId(installation.id);
    };

    const handleAddGroup = () => {
        if (selectedGroup && !userGroups.some(g => g.id === selectedGroup.id)) {
            const newGroup = {
                id: selectedGroup.id,
                group_name: selectedGroup.group_name, // Cambiado a `group_name`
                uniqueId: `group-${selectedGroup.id}-${Date.now()}`
            };
            setUserGroups(prev => [...prev, newGroup]);
            setSelectedGroupId('');
            setSelectedGroup(null);
        }
    };
    

    const handleAddInstallation = () => {
        if (selectedInstallation && !userInstallations.some(i => i.id === selectedInstallation.id)) {
            const newInstallation = {
                id: selectedInstallation.id,
                installation_name: selectedInstallation.name,
                uniqueId: `installation-${selectedInstallation.id}-${Date.now()}`
            };
            setUserInstallations(prev => [...prev, newInstallation]);
            setSelectedInstallationId('');
            setSelectedInstallation(null);
        }
    };

    const handleDeleteGroup = (groupToDelete) => {
   
        const updatedGroups = userGroups.filter(group => group.id !== groupToDelete.id);
        setUserGroups(updatedGroups);
    
    
        if (selectedGroup && selectedGroup.id === groupToDelete.id) {
            setSelectedGroup(null);
            setSelectedGroupId('');
        }
    
     
        if (onGroupsChange) {
            onGroupsChange(updatedGroups);
        }
    };
    

    const handleDeleteInstallation = (installationToDelete) => {
        setUserInstallations(prev => prev.filter(installation => installation.uniqueId !== installationToDelete.uniqueId));
    };

    if (roleLevel === 0 || !roleLevel) return null;

    return (
        <Paper 
            elevation={0} 
            variant="outlined" 
            sx={{ 
                p: { xs: 2, sm: 3 },
                height: '100%',
                borderRadius: { xs: 1, sm: 2 }
            }}
        >
            <Typography 
                variant="h6" 
                color="textPrimary" 
                gutterBottom
                sx={{ 
                    fontSize: { xs: '1.1rem', sm: '1.25rem' },
                    fontWeight: 500
                }}
            >
                {roleLevel === 1 
                    ? t("resources.users.sections.group_management")
                    : t("resources.users.sections.installation_management")
                }
            </Typography>
            <Divider sx={{ mb: { xs: 2, sm: 3 } }} />

            <Box sx={{ 
                '& .MuiFormControl-root': { mb: 2 },
                '& .selector-container': { 
                    width: '100%',
                    '& .MuiInputBase-root': {
                        width: '100%'
                    }
                }
            }}>
                <Box sx={{ mb: 2 }}>
                    <GroupSelector
                        selectedGroup={selectedGroupId}
                        onChange={handleGroupSelectorChange}
                    />
                </Box>

                {roleLevel > 1 && (
                    <Box sx={{ mb: 2 }}>
                        <ConditionalInstallationsSelector
                            selectedGroup={selectedGroupId}
                            selectedInstallation={selectedInstallationId}
                            onChange={handleInstallationSelectorChange}
                            disabled={!selectedGroupId}
                        />
                    </Box>
                )}

                <CustomButton
                    onClick={roleLevel === 1 ? handleAddGroup : handleAddInstallation}
                    icon={<AddIcon />}
                    disabled={roleLevel === 1 ? !selectedGroup : !selectedInstallation}
                    fullWidth
                    sx={{ 
                        mt: 2,
                        '& .MuiButton-startIcon': {
                            display: 'inline-flex !important',
                            mr: { xs: 1, sm: 1 },
                        }
                    }}
                    label = {t("resources.users.actions.add")}
                    showLabelOnMobile={true}
                >
                    
                </CustomButton>

                <Box sx={{ mt: 3, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {roleLevel === 1 
                        ? userGroups.map((group) => (
                            <Chip
                                key={group.id} 
                                label={group.group_name}
                                onDelete={() => handleDeleteGroup(group)} 
                                variant="outlined"
                                sx={{ m: 0.5 }}
                            />
                        ))
                        : userInstallations.map((installation) => (
                            <Chip
                                key={installation.uniqueId}
                                label={installation.installation_name}
                                onDelete={() => handleDeleteInstallation(installation)}
                                variant="outlined"
                                sx={{ m: 0.5 }}
                            />
                        ))
                    }
                </Box>
            </Box>
        </Paper>
    );
};

export default UserGroupsAndInstallations;