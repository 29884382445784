import React from 'react';
import { useListContext } from 'react-admin';
import { Box, Typography, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const MobileContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
   
}));

const MobileList = styled('div')({
    flex: 1,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    padding: '4px 2px',
    
});

const MobileRow = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    marginBottom: '20px',
}));

const RowHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    backgroundColor: theme.palette.grey[50],
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    
}));

const RowContent = styled('div')(({ theme }) => ({
    padding: '12px 16px',
    
}));

const FieldContainer = styled('div')({
    marginBottom: '12px',
    '&:last-child': {
        marginBottom: 0,
    },
});


const TitleLabel = styled('div')(({ theme }) => ({
    variant:'subtitle1',
    fontWeight:'850',
    color: theme.palette.primary,
    letterSpacing:'1px'
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.95rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    marginBottom: '4px',
}));

const FieldValue = styled('div')(({ theme }) => ({
    fontSize: '0.95rem',
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.palette.grey[500],
}));

const CustomDataGridMobile = ({ children }) => {
    const { data = [], isLoading } = useListContext();

    if (isLoading || !data.length) return null;

    const fields = React.Children.toArray(children);
   
    const actionFields = fields.filter(child => 
        child?.props?.source === undefined && child?.props?.label === ''
    );
    
    const dataFields = fields.filter(child => 
        !actionFields.includes(child)
    );

    const renderFieldValue = (field, record) => {
        if (field.props.render) {
            return field.props.render(record);
        }
        return record[field.props.source] || '-';
    };

    return (
        <MobileContainer>
            <MobileList>
                {data.map(record => (
                    <MobileRow key={record.id}>
                        <RowHeader>
                       
                            <TitleLabel>
                                {record[dataFields[0]?.props?.source] || record.id}
                            </TitleLabel>
                            
                           
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                {actionFields.map((field, index) => 
                                    React.cloneElement(field, { 
                                        key: index,
                                        record,
                                        sx: { padding: 0 }
                                    })
                                )}
                            </Box>
                        </RowHeader>

                        <RowContent>
                            {dataFields.slice(1).map((field, index) => {
                                const value = renderFieldValue(field, record);
                                if (!value || value === '-') return null;
                                
                                return (
                                    <FieldContainer key={index}>
                                        <FieldLabel component="div">
                                            {field.props.label}
                                        </FieldLabel>
                                        <FieldValue>
                                            {value}
                                        </FieldValue>
                                    </FieldContainer>
                                );
                            })}
                        </RowContent>
                    </MobileRow>
                ))}
            </MobileList>
        </MobileContainer>
    );
};

export default CustomDataGridMobile;




/*import React from 'react';
import { useListContext } from 'react-admin';
import { Box, Typography, Card, CardContent, Chip, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
    ...theme.components.MuiCard.styleOverrides.root({ theme }),
    
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
}));

const RowContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
}));

const FieldLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    fontSize: '0.9rem',
}));

const FieldValue = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(1.5),
    fontSize: '0.875rem',
    display: 'block', // Para garantizar que esté debajo del título
}));

const ActionsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

const ChipContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
}));

const CustomDataGridMobile = ({ children }) => {
    const { data = [], isLoading } = useListContext();

    if (isLoading) return null;
    if (!data.length) return null;

    const actionsField = React.Children.toArray(children).find(
        (child) => child?.props?.source === undefined && child?.props?.label === '' && child?.props?.sortable === false
    );

    const normalFields = React.Children.toArray(children).filter(
        (child) =>
            child?.props?.source &&
            child?.props?.source !== 'associations' &&
            child?.props?.source !== 'role_level'
    );

    const functionFields = React.Children.toArray(children).filter(
        (child) => typeof child?.props?.render === 'function'
    );

    return (
        <Box sx={{ 
            padding: 2,
            height: '100%',  
            overflow: 'auto' 
        }}>
            {data.map((record) => (
                <StyledCard key={record.id}>

                    <CardContent>
                        
                       
                        {actionsField && React.cloneElement(actionsField, { record })}

                      
                       
                        {normalFields.map((field) => (
                            <RowContainer key={field.props.source}>
                                <FieldLabel component="span">{field.props.label}</FieldLabel>
                                <FieldValue component="span">{record[field.props.source] || '-'}</FieldValue>
                            </RowContainer>
                        ))}

                      
                        {functionFields.map((field, index) => {
                            const content = field.props.render(record);
                            if (!content) return null;

                            return (
                                <RowContainer key={`function-${index}`}>
                                    <FieldLabel component="span">{field.props.label}</FieldLabel>
                                    <FieldValue component="span">{content}</FieldValue>
                                </RowContainer>
                            );
                        })}

                       
                    </CardContent>
                </StyledCard>
            ))}
        </Box>
    );
};

export default CustomDataGridMobile;*/


