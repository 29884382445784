import React from 'react';
import { List, TextField, useTranslate, Title, FunctionField } from 'react-admin';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import CustomDataGrid from '../common/CustomDatagrid';
import CustomCreateButton from '../common/CustomCreateButton';
import CustomExportButton from '../common/CustomExportButton';
import CustomEditButton from '../common/CustomEditButton';
import CustomDeleteButton from '../common/CustomDeleteButton';
import UserCreate from './UserCreate';
import UserEditDialog from './UserEditDialog';
import { translateRoleByLevel } from '../../utils/roleTranslations';
import {Chip} from '@mui/material';

const GroupActionsField = function GroupActionsField({ record }) {
    const translate = useTranslate();
    const currentUsername = localStorage.getItem('username');
    if (!record) return null;
    const isCurrentUser = record.username === currentUsername;
    
    return (
        <Box 
            display="flex" 
            gap={0.5}
            sx={{
                opacity: '1 !important',
                margin: '5px',
                visibility: 'visible !important',
                display: 'flex !important',
                '& .MuiButtonBase-root': {
                    visibility: 'visible !important',
                    opacity: '1 !important',
                    display: 'inline-flex !important'
                }
            }}
        >
            <CustomEditButton
                record={record}
                resource="users"
                EditComponent={UserEditDialog}
                dialogTitle={translate('resources.users.edit_page_title')}
                maxWidth={record?.role_level === 0 ? 'sm' : 'md'} 
            />
   
        {!isCurrentUser && (
            <CustomDeleteButton 
                record={record} 
                resource="users" 
                nameField="username"
                translateOptions={{ 
                    name: record.username 
                }}
              
            />
        )}

        </Box>
    );
};

const getAssociations = (record) => {
    if (!record) return [];
   
    if (record.role_level === 0 || record.groups === 'All' || record.installations === 'All') {
        return [];
    }
    
    if (record.role_name === 'Group Manager' && Array.isArray(record.groups)) {
        return record.groups;
    }
   
    if (Array.isArray(record.installations)) {
        return record.installations;
    }

    return [];
};

const UserGridList = (props) => {
    const translate = useTranslate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const AssociationsField = ({ record }) => {
        const items = getAssociations(record);
        if (!items.length) return null;

        return (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: theme.spacing(0.5),
                    maxWidth: isMobile ? '200px' : 'none',
                }}
            >
                {items.map((item, index) => (
                    <Chip
                    key={index}
                    label={item}
                    size="small"
                    
                />
                ))}
            </Box>
        );
    };


    return (
        <List
            {...props}
            perPage={25}
            title={<Title title={translate('resources.users.name')} />}
            actions={
                <Box
                    display="flex"
                    gap={theme.spacing(1)}
                    sx={{
                        width: isMobile ? '100%' : 'auto',
                        mt: theme.spacing(isMobile ? 1 : 0),
                        mb: theme.spacing(isMobile ? 1 : 2),
                        '& .MuiButton-root': {
                            minHeight: theme.spacing(isMobile ? 4 : 5),
                            fontSize: theme.typography.body2.fontSize,
                        },
                    }}
                >
                    <CustomCreateButton 
                        resource="users"
                        CreateComponent={UserCreate}
                        dialogTitle={translate('resources.users.create_page_title')}
                    />
                    <CustomExportButton label={translate('ra.action.export')} />
                </Box>
            }
            sx={{
                height: '100%',
                '& .MuiTablePagination-root': {
                    marginTop: 'auto',
                },
            }}
        >

            <Box sx={{ flexGrow: 1 }}>
                <CustomDataGrid>
                    <GroupActionsField label="" sortable={false}/>
                    
                    <TextField
                        source="username"
                        label={translate('resources.users.fields.user_name')}
                    />
                  
                  <FunctionField
                        source="role_level" 
                        label={translate('resources.users.fields.role_name')}
                        render={(record) =>
                            translateRoleByLevel(record.role_level, translate) 
                        }
                    />
                    
                    <FunctionField
                        label={translate('resources.users.fields.associations')}
                        source="associations" 
                        render={(record) => {
                            if (!record) return null;
                            const items = getAssociations(record);
                            if (!items.length) return undefined;
                            return <AssociationsField record={record} />;
                        }}
                    />
                </CustomDataGrid>
            </Box>
        </List>
    );
};

export default UserGridList;
