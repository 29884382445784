import React from 'react';
import { Box, useTheme, useMediaQuery, Card } from '@mui/material';

const ToolbarSection = ({ leftControls, rightControls }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Card
      elevation={2}
      sx={{
        width:'100%',
        p: 2,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        alignItems: isMobile ? 'center' : 'flex-start',
        gap: theme.spacing(2),
        border: 'none',
        backgroundColor: 'transparent'
      }}
    >
    
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: theme.spacing(2),
          alignItems: isMobile ? 'center' : 'flex-start',
          flexGrow: isMobile ? 1 : 0,
          width: isMobile ? '100%' : 'auto',
           backgroundColor: 'transparent'
        }}
      >
        {leftControls}
      </Box>

    
      <Box
        sx={{
          display: 'flex',
          
          gap: theme.spacing(1),
          alignItems: 'center',
           backgroundColor: 'transparent'
        }}
      >
        {rightControls}
      </Box>
    </Card>
  );
};

export default ToolbarSection;
