const englishCustomMessages = {
  
  buttons:
  {
    search:"Filter",
  },
  pages: {
    dashboard:{
      name:"Status",
      occupied: "Occupied",
      available: "Unoccupied",
      no_devices: "Nothing to display for now",
      no_devices_description: "Add content or adjust the filters to continue.",
      
      select_order_by:{
        label: "Order by",
        option_1: "Device name",
        option_2: "Occupancy status",
        option_3: "Last communication",
      } ,
    },
    login:{
      welcome: "Roommatik Eco",
      subtitle: "Sign in to access your account",
      login_button: "Sign in",
    },
    SensorDetailsPopover:{
      no_results: "No sensors found",
    }
  },
  resources: {
    group: {
      delete_confirmation: "Are you sure you want to delete group '%{name}'?",
      delete_title: "Delete group",
    },
    groups: {
      name: "Groups",
      edit_title: "Update group",
      create_title: "Create group",
      associated_installations: "Facilities",
      fields: {
        id: "ID",
        group_name: "Group Name",
        owner_info: "Owner Info",
        description: "Description",
      },
      actions: {
        delete_conflict: "Please, delete first the associated facilities",
        installations_loading: "Loading ... ",
        installations_empty: "No associated facilities found",
        create: "Create",
      },
    },
    installations: {
      edit_page_title: "Update facility",
      create_page_title: "Create a new facility",
      delete_title: "Delete facility",
      error_loading_installations: "Error loading the facilities",
      delete_confirmation: "Are you sure you want to delete the facility '%{name}'?",
      error_fetching: "Error fetching facilities. Please try again.",
      name: "Facilities",
      all: "All",
      fields: {
        id: "ID",
        group_name: "Group name",
        installation_name: "Facility name",
        api_key: "API Key",
        max_devices: "Max nr. of devices",
        license_expiration: "License expiration",
      },
      actions: {
        change_api_key: "Change API Key",
        confirm_change_api_key_title: "Confirm Change API Key",
        confirm_change_api_key_text: "Are you sure you want to change the API Key for this facility?",
        api_key_generated_title: "API Key Generated",
        api_key_generated_text: "The API key has been successfully generated. Please copy it now as it will not be shown again.",
        api_key_copied: "API key copied to clipboard",
        api_key_copy_failed: "Failed to copy API key",
        cancel: "Cancel",
        confirm: "Confirm",
        copy: "Copy",
        close: "Close",
        create: "Create",
        delete_conflict: "Please, delete first the associated devices",
      },
    },
    devices: {
      name: "Controllers",
      all:"All",
      error_loading: "Error loading controllers. Please try again later.",
      delete_title:"Delete controller",
      delete_confirmation: "Are you sure you want to delete the controller '%{name}'?",
      loading: "Loading",
      select_label: "Labels",
      no_label: "All",
      manage_labels: "Labels",
      create_label: "Create new label",
      delete_label_confirmation:  "Are you sure you want to delete the label?",
      fields: {
        id: "ID",
        installation_id: "Facility ID",
        installation_name: "Facility",
        device_name: "Name",
        device_type: "Type",
        occupancy_status: " ",
        last_event_time: "Last Event",
        last_config_time: "Last Config",
        modes: "Modes",
        technical_info: "Net",
        inactivity_duration: "Inactivity",
        circuit_disconnect: "Power Cut",
        ac_disconnect: "AC Off",
        window_open: "Open Window",
        times: "Times",
        firmware: "Firmware",
        ip: "IP",
        mac: "MAC",
        ac: "AC",
        power: "Power",
        labels: "Labels"
      },
      tooltips: {
        firmware: "Device firmware version",
        ip: "Device IP address",
        mac: "Device MAC address",
        ac_mode: "Air conditioning operation mode",
        power_mode: "Power operation mode",
        inactivity_duration: "Time without movement to determine absence",
        circuit_disconnect: "Time before cutting power after detecting absence",
        ac_disconnect: "Time before turning off AC after detecting absence",
        window_open: "Time from opening a window until AC disconnection",
        view_sensors: "View sensors"
      },
      values:{
            unknown:"-",
      },
    },
    events: {
      name: "Events",
      fields: {
          id: "ID",
          device_id: "Controller ID",
          device_name: "Controller",
          event_key: "Key",
          event_value: "Value",
          timestamp: "Date time",
          timestamp_from: "From",
          timestamp_to: "To"
      },
      delete_all: "Delete all events",
      delete_installation: "Delete events for this installation",
      delete_device: "Delete events for this device",
      delete_confirmation_title: "Delete Confirmation",
      delete_confirmation_content: "Are you sure you want to delete the events?",
      error_fetching: "Error fetching events. Please try again.",
      deleting: "Deleting events, please wait"
  },
    users: {
      name: "Users",
      edit_page_title: "Update user",
      create_page_title: "Create a new user",
      delete_title: "Delete User",
      change_password_message: "The password must be at least 8 characters long and include uppercase letters, lowercase letters, numbers, and symbols",
      delete_confirmation: "Are you sure you want to delete user '%{name}'?",
      password_requirements: "The password must be at least 8 characters long, including uppercase letters, lowercase letters, numbers, and symbols.",
      change_password_message: "The password must be at least 8 characters long, including uppercase letters, lowercase letters, numbers, and symbols.",
      fields: {
        id: "ID",
        user_name: "Name",
        role_name: "Role",
        active: "Active",
        email: "Email",
        installations: "Facilities",
        associations: "Permissions",
        groups: "Groups",
        new_password: "New password",
        password: "Password"
      },
      roles:{
        Maint2: "Data viewer",
        Maint1: "Supervisor",
        InstallationManager: "Facility manager",
        GroupManager:"Organization manager",
        Superuser: "System administrator",
        unknown: "Unknown"
      },
    
      actions:{
        change_password: "Change password",
        add: "Add",
        password_changed_success: "Password changed successfully",
        create: "Create",
      },
      sections:{
        user_info: "User info",
        group_management: "Permissions",
        installation_management: "Permissions"
      }
    },
    occupancyAnalytics: {
      name: "Occupancy Analytics",
      hotelSelector: "Select Hotel",
      dateRange: "Select Date Range",
      generateCharts: "Generate Charts",
      lastWeek: "Last Week",
      lastMonth: "Last Month",
      customDate: "Custom Date Range",
      occupancyChart: "Occupancy Chart",
      highMovementHours: "High Movement Hours",
      trendAnalysis: "Trend Analysis",
      showTable: "Show Data Table"
    },
    sensors: {
      types: {
          pir: 'Motion Sensor',
          door: 'Door Sensor',
          window: 'Window Sensor'
      },
      subtypes: {
          wireless: 'Wireless',
          wired: 'Wired',
          Main: 'Wired'
      }
    },
  },
 
};

export default englishCustomMessages;
