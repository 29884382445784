import React, { useEffect, useState } from 'react';
import {
    Popover,
    CircularProgress,
    Typography,
    Box,
    useTheme
} from '@mui/material';
import { useTranslate, useDataProvider } from 'react-admin';
import {
    SensorOccupied,
    SensorDoorOutlined,
    SensorWindowOutlined,
    CableOutlined,
    BluetoothOutlined
} from '@mui/icons-material';

const SensorDetailsPopover = ({ deviceId, deviceName, mouseX, mouseY, onClose }) => {
    const [sensors, setSensors] = useState([]);
    const [loading, setLoading] = useState(false);
    const t = useTranslate();
    const theme = useTheme();
    const dataProvider = useDataProvider();
    const open = mouseX !== null && mouseY !== null;

    useEffect(() => {
        const fetchSensors = async () => {
            if (deviceId && mouseX && mouseY) {
                setLoading(true);
                try {
                    const { data } = await dataProvider.getList('sensors', {
                        filter: { device_id: deviceId },
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'sensor_type', order: 'ASC' },
                    });
                    setSensors(data);
                } catch (error) {
                    console.error('Error fetching sensors:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchSensors();
    }, [deviceId, mouseX, mouseY, dataProvider]);

    const getSensorIcon = (sensor) => {
        const isWireless = sensor.subtype === "wireless";
        const iconStyle = {
            fontSize: '1.25rem',
            color: isWireless ? 
                theme.palette.blue[600] : 
                theme.palette.grey[500]
        };

        switch (sensor.sensor_type?.toLowerCase()) {
            case 'pir':
            case 'motion':
            case 'motion sensor':
                return <SensorOccupied sx={iconStyle} />;
            case 'window':
            case 'windowsensor':
                return <SensorWindowOutlined sx={iconStyle} />;
            case 'door':
            case 'doorsensor':
                return <SensorDoorOutlined sx={iconStyle} />;
            default:
                return null;
        }
    };

    const getSensorTranslation = (sensor) => {
        return t(`resources.sensors.types.${sensor.sensor_type?.toLowerCase()}`, {
            defaultValue: sensor.sensor_type
        });
    };

    const getSubtypeTranslation = (sensor) => {
        return t(`resources.sensors.subtypes.${sensor.subtype?.toLowerCase()}`, {
            defaultValue: sensor.subtype
        });
    };

    return (
        <Popover
            open={open}
            anchorReference="anchorPosition"
            anchorPosition={open ? { top: mouseY, left: mouseX } : undefined}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            elevation={3}
            sx={{
                '& .MuiPopover-paper': {
                    width: '384px',
                    maxHeight: '400px',
                    overflow: 'hidden',
                    mt: 0.5,
                    borderRadius: 2,
                    bgcolor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                }
            }}
        >
            {loading ? (
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={24} />
                </Box>
            ) : (
                <Box>
                    <Box sx={{ 
                        bgcolor: theme.palette.primary.main,
                        p: 2,
                        borderBottom: `1px solid ${theme.palette.divider}`
                    }}>
                        <Typography 
                            variant="subtitle1" 
                            align="center" 
                            sx={{
                                color: theme.palette.primary.contrastText,
                                fontWeight: 500,
                                letterSpacing: '0.5px'
                            }}
                        >
                            {deviceName}
                        </Typography>
                    </Box>

                    <Box sx={{ 
                        maxHeight: '320px', 
                        overflow: 'auto',
                        bgcolor: theme.palette.background.paper
                    }}>
                        {sensors.length > 0 ? (
                            sensors.map((sensor) => (
                                <Box
                                    key={sensor.id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        p: 2,
                                        borderBottom: `1px solid ${theme.palette.divider}`,
                                        transition: 'all 0.2s ease',
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            {sensor.subtype === "wired" ? (
                                                <CableOutlined sx={{ 
                                                    fontSize: '1.25rem', 
                                                    color: theme.palette.grey[500]
                                                }} />
                                            ) : (
                                                <BluetoothOutlined sx={{ 
                                                    fontSize: '1.25rem', 
                                                    color: theme.palette.blue[600]
                                                }} />
                                            )}
                                            {getSensorIcon(sensor)}
                                        </Box>
                                        <Box>
                                            <Typography 
                                                variant="subtitle2"
                                                className="sensor-type"
                                                sx={{ 
                                                    color: theme.palette.text.primary,
                                                    transition: 'color 0.2s ease',
                                                    fontWeight: 500
                                                }}
                                            >
                                                {getSensorTranslation(sensor)}
                                            </Typography>
                                            <Typography 
                                                variant="caption" 
                                                sx={{ color: theme.palette.text.secondary }}
                                            >
                                                {getSubtypeTranslation(sensor)}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography 
                                        variant="caption"
                                        sx={{ 
                                            fontFamily: 'monospace',
                                            color: theme.palette.text.secondary,
                                            bgcolor: theme.palette.mode === 'light' ? 
                                                theme.palette.grey[100] : 
                                                theme.palette.background.default,
                                            px: 1,
                                            py: 0.5,
                                            borderRadius: 1
                                        }}
                                    >
                                        {sensor.mac_address}
                                    </Typography>
                                </Box>
                            ))
                        ) : (
                            <Box sx={{ p: 3, textAlign: 'center', color: theme.palette.text.secondary }}>
                                {t('pages.SensorDetailsPopover.no_results')}
                            </Box>
                        )}
                    </Box>
                </Box>
            )}
        </Popover>
    );
};

export default SensorDetailsPopover;