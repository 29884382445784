import React, { useState } from 'react';
import { useTranslate, useRefresh } from 'react-admin';
import { Confirm } from 'react-admin';
import { CircularProgress, Box } from '@mui/material';
import { CustomButton } from '../common/CustomButton';
import { CustomDeviceIcon, CustomInstallationIcon } from '../pages/CustomIcons';
import { DeleteTwoTone } from '@mui/icons-material';

const DeleteEventsButton = ({ type, onConfirm }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false); 
    const translate = useTranslate();
    const refresh = useRefresh();

    const handleConfirm = async () => {
        setLoading(true); 
        try {
            await onConfirm();
            refresh(); 
        } catch (error) {
            console.error('Delete error:', error);
        } finally {
            setLoading(false);
            setOpen(false); 
        }
    };

    const getIcon = () => {
        if (type === 'all') return <DeleteTwoTone  />;
        if (type === 'installation') return <CustomInstallationIcon  />;
        if (type === 'device') return <CustomDeviceIcon  />;
        return <DeleteTwoTone />;
    };

    return (
        <>
            <CustomButton
                icon={loading ? <CircularProgress size={20} color="inherit" /> : getIcon()} 
                onClick={() => setOpen(true)}
                disabled={loading}
                label={
                    type === 'all'
                        ? translate('resources.events.delete_all')
                        : type === 'installation'
                        ? translate('resources.events.delete_installation')
                        : translate('resources.events.delete_device')
                }
            />
            <Confirm
                open={open}
                title={translate('resources.events.delete_confirmation_title')}
                content={
                    loading ? (
                        <Box display="flex" alignItems="center" justifyContent="center" p={2}>
                            <CircularProgress />
                            {translate('resources.events.deleting')}
                        </Box>
                    ) : (
                        translate('resources.events.delete_confirmation_content', { type })
                    )
                }
                onConfirm={handleConfirm}
                onClose={() => !loading && setOpen(false)} // Solo permitir cerrar si no está cargando
            />
        </>
    );
};

export default DeleteEventsButton;

  
