import React, { useState, useEffect } from 'react';
import { List, TextField, FunctionField, useTranslate, useDataProvider, useRefresh, useNotify } from 'react-admin';
import { 
    Chip, 
    Box, 
    Tooltip, 
    
    IconButton, 
    useTheme, 
    useMediaQuery,
} from '@mui/material';
import { Sensors, Label } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/es';
import CustomDataGrid from '../common/CustomDatagrid';
import CustomExportButton from '../common/CustomExportButton';
import CustomDeleteButton from '../common/CustomDeleteButton';
import SensorDetailsPopover from '../pages/SensorDetailsPopover';
import SearchForm from '../filters/SearchForm';
import { useListContext } from 'react-admin'; 
import LabelManagementPopover from './LabelManagementPopover';
import { CustomButton } from '../common/CustomButton';


const DeviceList = (props) => {
   
    const [searchParams, setSearchParams] = useState({
        installationId: '',
        labelId: '',
        sortBy: 'device_name'
    });

    return (
        <List
            {...props}
            filterDefaultValues={{
                installation_id: searchParams.installationId,
                ...(searchParams.labelId ? { label_id: searchParams.labelId } : {})
            }}
            sort={{ field: 'device_name', order: 'ASC' }}
            perPage={50}  
        >
             <ListContents 
                setSearchParams={setSearchParams} 
                searchParams={searchParams} 
            />
            
        </List>
    );
};


const ListContents = ({  setSearchParams, searchParams }) => {
    const translate = useTranslate();
    const { setFilters } = useListContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const theme = useTheme();
    const notify = useNotify();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState(false);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [selectedDeviceName, setSelectedDeviceName] = useState(null);
    const [mouseX, setMouseX] = useState(null);
    const [mouseY, setMouseY] = useState(null);
    const intervalTime = 15000;
    const roleLevel = parseInt(localStorage.getItem('level'), 10);

    const [labelPopoverState, setLabelPopoverState] = useState({
        deviceId: null,
        deviceName: null,
        mouseX: null,
        mouseY: null
    });


    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, intervalTime);

        return () => clearInterval(interval);
    }, [refresh, intervalTime]);

    const handleSearch = (params) => {
        setSearchParams(params);
        setFilters({
            installation_id: params.installationId,
            ...(params.labelId ? { label_id: params.labelId } : {})
        });
    };

    const handleSensorClick = (event, deviceId, deviceName) => {
        event.stopPropagation();
        setMouseX(event.clientX);
        setMouseY(event.clientY);
        setSelectedDeviceId(deviceId);
        setSelectedDeviceName(deviceName);
    };

    const handleClosePopover = () => {
        setMouseX(null);
        setMouseY(null);
        setSelectedDeviceId(null);
    };

    const handleCloseLabelPopover = () => {
        setLabelPopoverState({
            deviceId: null,
            deviceName: null,
            mouseX: null,
            mouseY: null
        });
    };

    const handleDeleteClick = async (record) => {
        try {
            await dataProvider.getList('sensors', {
                filter: { device_id: record?.id },
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'sensor_type', order: 'ASC' },
            });
            return true;
        } catch (error) {
            notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
            return false;
        }
    };

   const handleLabelClick = (event, deviceId, deviceName) => {
        event.stopPropagation();
        setLabelPopoverState({
            deviceId,
            deviceName,
            mouseX: event.clientX,
            mouseY: event.clientY
        });
    };



    const formatElapsedTime = (date) => {
        const locale = translate('locale') === 'es' ? 'es' : 'en-gb';
        moment.locale(locale);
        return moment(date).fromNow();
    };

    

    const TechnicalInfo = ({ record }) => (
        <Box
            component="span"
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: 'wrap',
                gap: 1,
                maxWidth: '210px',
            }}
        >
            {record.firmware_version && <Chip
                size="small"
                label={`Firmware: ${record.firmware_version}`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />}
            {record.ip_address && <Chip
                size="small"
                label={`IP: ${record.ip_address}`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />}
            {record.mac_address && <Chip
                size="small"
                label={`MAC: ${record.mac_address}`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />}
        </Box>
    );
    
    const OperationModes = ({ record }) => (
        <Box
            component="span"
            sx={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                flexWrap: 'wrap',
                gap: 1,
                maxWidth: '210px',
            }}
        >
            <Tooltip title={translate('resources.devices.tooltips.ac_mode')} arrow>
                <Chip
                    size="small"
                    label={`${translate('resources.devices.fields.ac')}: ${
                        record.ac_mode || translate('resources.devices.values.unknown')
                    }`}
                    sx={{
                        backgroundColor: 'transparent !important',
                        border: '1px solid ' + theme.palette.grey[300],
                    }}
                />
            </Tooltip>
            <Tooltip title={translate('resources.devices.tooltips.power_mode')} arrow>
                <Chip
                    size="small"
                    label={`${translate('resources.devices.fields.power')}: ${
                        record.power_mode || translate('resources.devices.values.unknown')
                    }`}
                    sx={{
                        backgroundColor: 'transparent !important',
                        border: '1px solid ' + theme.palette.grey[300],
                    }}
                />
            </Tooltip>
        </Box>
    );
    
    const TimingInfo = ({ record }) => (
        <Box
            component="span"
            sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
                gap: 1,
            }}
        >
            {record.inactivity_duration && (
                <Chip
                    size="small"
                    label={`${translate('resources.devices.fields.inactivity_duration')}: ${record.inactivity_duration}s`}
                    sx={{
                        backgroundColor: 'transparent !important',
                        border: '1px solid ' + theme.palette.grey[300],
                    }}
                />
            )}
            {record.circuit_disconnect_delay && (
                <Chip
                    size="small"
                    label={`${translate('resources.devices.fields.circuit_disconnect')}: ${record.circuit_disconnect_delay}s`}
                    sx={{
                        backgroundColor: 'transparent !important',
                        border: '1px solid ' + theme.palette.grey[300],
                    }}
                />
            )}
            {record.ac_disconnect_delay && (
                <Chip
                    size="small"
                    label={`${translate('resources.devices.fields.ac_disconnect')}: ${record.ac_disconnect_delay}s`}
                    sx={{
                        backgroundColor: 'transparent !important',
                        border: '1px solid ' + theme.palette.grey[300],
                    }}
                />
            )}
            {record.ac_disconnect_window_open_delay && (
                <Chip
                    size="small"
                    label={`${translate('resources.devices.fields.window_open')}: ${record.ac_disconnect_window_open_delay}s`}
                    sx={{
                        backgroundColor: 'transparent !important',
                        border: '1px solid ' + theme.palette.grey[300],
                    }}
                />
            )}
        </Box>
    );
    
    const GroupActionsField = ({ record }) => {
    
        if (!record) return null;
    
        return (
            <Box component="span" sx={{ display: 'flex', gap: 1 }}>
                <CustomButton
                    size="small"
                    icon=<Sensors size="small" />
                    
                    onClick={(event) => handleSensorClick(event, record.id, record.device_name)}
                />

                {roleLevel <= 3 && 
                <>
                    

                    
                    <CustomButton
                        size="small"
                        icon = <Label size = "small"/>
                        onClick={(event) => handleLabelClick(event, record.id, record.device_name)}
                        
                    />
                   

                    <CustomDeleteButton
                        record={record}
                        resource="devices"
                        onClick={handleDeleteClick}
                        translateOptions={{ name: record.device_name }}
                        onSuccess={() => {
                            refresh();
                        }}
                        onError={(error) => {
                            notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
                        }}
                    />
                    
                
            
            </>
                }
            </Box>
        );
    };

    return (
        <>
            <SearchForm 
                onSearch={handleSearch}
                loading={loading}
                collapsible={true}
                orderOptions={[
                    { value: 'device_name', label: translate('resources.devices.fields.device_name') },
                    { value: 'last_event_time', label: translate('resources.devices.fields.last_event_time') }
                ]}
            />

            <CustomDataGrid>

                <FunctionField
                    label=""
                    render={(record) => <GroupActionsField record={record} />}
                    sx={{
                        position: 'relative',
                        '& .MuiBox-root': {
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        },
                    }}
                />

<FunctionField
    label={translate('resources.devices.fields.labels')}
    render={(record) => (
        <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,  
            minHeight: '48px', 
            px: 1 
        }}>
            <Box sx={{ 
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: 1,  
                flex: 1,
                py: 1  
            }}>
                {record.labels?.map((label, index) => (
                    <Chip
                    key={`label-${record.id}-${label.labelId}-${index}`}
                    size="small"
                    label={label.labelName}
                    onDelete={() => { }}
                    sx={{
                       
                        backgroundColor: `${theme.palette.primary.main} !important`,
                        borderRadius:'20px',
                        padding:'5px',
                        
                        '& .MuiChip-label': {
                            px: 1.5,
                           
                            color: theme.palette.primary.contrastText,
                            lineHeight: 2,
                        },
                        '& .MuiChip-deleteIcon': {
                           
                            color: theme.palette.primary.contrastText,
                            opacity: 0.7,
                           
                            '&:hover': {
                                color: theme.palette.error.main,
                                opacity: 1,
                            },
                        },
                        transition: 'all 0.2s ease',
                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}12 !important`,
                            
                            transform: 'translateY(-1px)',
                        },
                    }}
                />
                ))}
            </Box>
            
        </Box>
    )}
/>





                
                <TextField
                    source="device_name"
                    label={translate('resources.devices.fields.device_name')}
                />
                <FunctionField
                    source="last_event_time"
                    label={translate('resources.devices.fields.last_event_time')}
                    render={(record) =>
                        record?.last_event_time ? formatElapsedTime(record.last_event_time) : '-'
                    }
                />
                <FunctionField
                    label={translate('resources.devices.fields.technical_info')}
                    render={(record) => <TechnicalInfo record={record} />}
                />
                <FunctionField
                    label={translate('resources.devices.fields.modes')}
                    render={(record) => <OperationModes record={record} />}
                />
                <FunctionField
                    label={translate('resources.devices.fields.times')}
                    render={(record) => <TimingInfo record={record} />}
                />

            </CustomDataGrid>
                    
            <SensorDetailsPopover
                deviceId={selectedDeviceId}
                deviceName={selectedDeviceName}
                mouseX={mouseX}
                mouseY={mouseY}
                onClose={handleClosePopover}
            />

            <LabelManagementPopover
                deviceId={labelPopoverState.deviceId}
                deviceName={labelPopoverState.deviceName}
                installationId={searchParams.installationId} // Ahora searchParams está disponible
                mouseX={labelPopoverState.mouseX}
                mouseY={labelPopoverState.mouseY}
                onClose={handleCloseLabelPopover}
                onRefresh={refresh}
            />
        </>
    );
};

export default DeviceList;





/*import React, { useState, useEffect } from 'react';
import { List, TextField, FunctionField, useTranslate, useDataProvider, useRefresh, useNotify } from 'react-admin';
import { 
    CircularProgress, 
    Chip, 
    Box, 
    Tooltip, 
    IconButton, 
    useTheme, 
    useMediaQuery,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/es';
import CustomDataGrid from '../common/CustomDatagrid';
import ToolbarSection from '../filters/ToolbarSection'; 
import InstallationsSelector from '../filters/InstallationsSelector';
import SensorDetailsPopover from '../pages/SensorDetailsPopover';
import CustomExportButton from '../common/CustomExportButton';
import CustomDeleteButton from '../common/CustomDeleteButton';
import LabelSelector from '../filters/LabelSelector';

const DeviceList = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const theme = useTheme();
    const notify = useNotify();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [selectedDeviceName, setSelectedDeviceName] = useState(null);
    const [mouseX, setMouseX] = useState(null);
    const [mouseY, setMouseY] = useState(null);
    const intervalTime = 15000;
    const roleLevel = parseInt(localStorage.getItem('level'), 10);
    const [filters, setFilters] = useState({ installation_id: null, label_id: null });
    const [loading, setLoading] = useState(true);
    const [selectedInstallation, setSelectedInstallation] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);

    useEffect(() => {
        const fetchInstallations = async () => {
            try {
                const { data } = await dataProvider.getList('installations', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'installation_name', order: 'ASC' },
                });
                if (data.length > 0) {
                    setSelectedInstallation(data[0].id);
                    setFilters((prev) => ({ ...prev, installation_id: data[0].id }));
                }
            } catch (error) {
                console.error('Error fetching installations:', error);
                notify('Error loading installations', { type: 'error' });
            } finally {
                setLoading(false);
            }
        };

        fetchInstallations();
    }, [dataProvider, notify]);

    useEffect(() => {
        const interval = setInterval(() => {
            refresh();
        }, intervalTime);

        return () => clearInterval(interval);
    }, [refresh, intervalTime]);

  

const handleInstallationChange = (installation) => {
    setSelectedInstallation(installation.id);
    setFilters((prev) => ({ ...prev, installation_id: installation.id }));
    setSelectedLabel(null);
};

const handleLabelChange = (labelId) => {
    setSelectedLabel(labelId);
    setFilters((prev) => ({ ...prev, label_id: labelId }));
};

const handleSensorClick = (event, deviceId, deviceName) => {
    event.stopPropagation();
    setMouseX(event.clientX);
    setMouseY(event.clientY);
    setSelectedDeviceId(deviceId);
    setSelectedDeviceName(deviceName);
};

const handleClosePopover = () => {
    setMouseX(null);
    setMouseY(null);
    setSelectedDeviceId(null);
};

const handleDeleteClick = async (record) => {
    try {
        await dataProvider.getList('sensors', {
            filter: { device_id: record?.id },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'sensor_type', order: 'ASC' },
        });
        return true;
    } catch (error) {
        notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
        return false;
    }
};

const formatElapsedTime = (date) => {
    const locale = translate('locale') === 'es' ? 'es' : 'en-gb';
    moment.locale(locale);
    return moment(date).fromNow();
};

const TechnicalInfo = ({ record }) => (
    <Box
        component="span"
        sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            flexWrap: 'wrap',
            gap: 1,
            maxWidth: '210px',
        }}
    >
        {record.firmware_version && <Chip
            size="small"
            label={`Firmware: ${record.firmware_version}`}
            sx={{
                backgroundColor: 'transparent !important',
                border: '1px solid ' + theme.palette.grey[300],
            }}
        />}
        {record.ip_address && <Chip
            size="small"
            label={`IP: ${record.ip_address}`}
            sx={{
                backgroundColor: 'transparent !important',
                border: '1px solid ' + theme.palette.grey[300],
            }}
        />}
        {record.mac_address && <Chip
            size="small"
            label={`MAC: ${record.mac_address}`}
            sx={{
                backgroundColor: 'transparent !important',
                border: '1px solid ' + theme.palette.grey[300],
            }}
        />}
    </Box>
);

const OperationModes = ({ record }) => (
    <Box
        component="span"
        sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            flexWrap: 'wrap',
            gap: 1,
            maxWidth: '210px',
        }}
    >
        <Tooltip title={translate('resources.devices.tooltips.ac_mode')} arrow>
            <Chip
                size="small"
                label={`${translate('resources.devices.fields.ac')}: ${
                    record.ac_mode || translate('resources.devices.values.unknown')
                }`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />
        </Tooltip>
        <Tooltip title={translate('resources.devices.tooltips.power_mode')} arrow>
            <Chip
                size="small"
                label={`${translate('resources.devices.fields.power')}: ${
                    record.power_mode || translate('resources.devices.values.unknown')
                }`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />
        </Tooltip>
    </Box>
);

const TimingInfo = ({ record }) => (
    <Box
        component="span"
        sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            gap: 1,
        }}
    >
        {record.inactivity_duration && (
            <Chip
                size="small"
                label={`${translate('resources.devices.fields.inactivity_duration')}: ${record.inactivity_duration}s`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />
        )}
        {record.circuit_disconnect_delay && (
            <Chip
                size="small"
                label={`${translate('resources.devices.fields.circuit_disconnect')}: ${record.circuit_disconnect_delay}s`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />
        )}
        {record.ac_disconnect_delay && (
            <Chip
                size="small"
                label={`${translate('resources.devices.fields.ac_disconnect')}: ${record.ac_disconnect_delay}s`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />
        )}
        {record.ac_disconnect_window_open_delay && (
            <Chip
                size="small"
                label={`${translate('resources.devices.fields.window_open')}: ${record.ac_disconnect_window_open_delay}s`}
                sx={{
                    backgroundColor: 'transparent !important',
                    border: '1px solid ' + theme.palette.grey[300],
                }}
            />
        )}
    </Box>
);

const GroupActionsField = ({ record }) => {

    if (!record) return null;

    return (
        <Box component="span" sx={{ display: 'flex', gap: 1 }}>
            <IconButton
                size="small"
                onClick={(event) => handleSensorClick(event, record.id, record.device_name)}
            >
                <MoreVert sx={{ fontSize: '1.2rem' }} />
            </IconButton>

            {roleLevel <= 3 && 
                <CustomDeleteButton
                    record={record}
                    resource="devices"
                    onClick={handleDeleteClick}
                    translateOptions={{ name: record.device_name }}
                    onSuccess={() => {
                        refresh();
                    }}
                    onError={(error) => {
                        notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
                    }}
                />
            }
        </Box>
    );
};

if (loading) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flexDirection="column"
        >
            <CircularProgress />
            <Box mt={2}>{translate('resources.devices.loading')}</Box>
        </Box>
    );
}

return (
    <List
        {...props}
        filterDefaultValues={filters}
        sort={{ field: 'device_name', order: 'ASC' }}
        perPage={50}
        actions={
            <ToolbarSection
                leftControls={
                    <>
                        <InstallationsSelector
                            selectedInstallation={selectedInstallation}
                            onChange={handleInstallationChange}
                        />
                        <LabelSelector
                            installationId={selectedInstallation}
                            selectedLabel={selectedLabel}
                            onChange={handleLabelChange}
                        />
                    </>
                }
                rightControls={

                    <CustomExportButton label={translate('ra.action.export')} />
              
                }
            />
        }
    >
        <CustomDataGrid>
            <FunctionField
                label=""
                render={(record) => <GroupActionsField record={record} />}
                sx={{
                    position: 'relative',
                    '& .MuiBox-root': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                }}
            />

            

            <TextField
                source="device_name"
                label={translate('resources.devices.fields.device_name')}
            />
            <FunctionField
                source="last_event_time"
                label={translate('resources.devices.fields.last_event_time')}
                render={(record) =>
                    record?.last_event_time ? formatElapsedTime(record.last_event_time) : '-'
                }
            />
            <FunctionField
                label={translate('resources.devices.fields.technical_info')}
                render={(record) => <TechnicalInfo record={record} />}
            />
            <FunctionField
                label={translate('resources.devices.fields.modes')}
                render={(record) => <OperationModes record={record} />}
            />
            <FunctionField
                label={translate('resources.devices.fields.times')}
                render={(record) => <TimingInfo record={record} />}
            />
        </CustomDataGrid>

        <SensorDetailsPopover
            deviceId={selectedDeviceId}
            deviceName={selectedDeviceName}
            mouseX={mouseX}
            mouseY={mouseY}
            onClose={handleClosePopover}
        />

    </List>
);
};

export default DeviceList;*/
