import React, { useEffect, useState } from 'react';
import { 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  CircularProgress,
  FormHelperText,
  useTheme
} from '@mui/material';
import { useDataProvider, useTranslate } from 'react-admin';




const InstallationsSelectorD = ({ 
    onChange, 
    selectedInstallation, 
    disabled,
    installations // Nueva prop
  }) => {
    const translate = useTranslate();
    const theme = useTheme();
  
    return (
      <FormControl
        fullWidth
        size="small"
        sx={{
          minWidth: { xs: '100%', sm: 200, md: 250 },
          maxWidth: 300,
        }}
      >
        <InputLabel id="installations-selector-label">
          {translate('resources.installations.name')}
        </InputLabel>
  
        <Select
          labelId="installations-selector-label"
          value={selectedInstallation || ''}
          label={translate('resources.installations.name')}
          onChange={(event) => onChange({ 
            id: event.target.value
          })}
          disabled={disabled}
          sx={{
            '& .MuiSelect-select': {
              py: 1.5,
            },
          }}
        >
          {installations?.map((installation) => (
            <MenuItem 
              key={installation.id} 
              value={installation.id}
              sx={{
                '&:hover': {
                  backgroundColor: `${theme.palette.primary.main}08`,
                },
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main}14`,
                  '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}20`,
                  },
                },
              }}
            >
              {installation.installation_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  export default InstallationsSelectorD;