import React from 'react';
import { Paper, Box, Typography, Chip } from '@mui/material';
import { Person, WifiOff } from '@mui/icons-material';


const DeviceCard = ({ device, selectedLabel, translate }) => {
  const filteredLabels = device.labels.filter((label) => label.labelId !== selectedLabel);
  const hasOtherLabels = filteredLabels.length > 0;
    
  return (
    <Paper
      elevation={0}
      sx={{
        height: { xs: '100px', sm: '110px' },
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: { xs: 1, sm: 1.5 },



        backgroundColor: !device.is_online
        ? 'rgba(241, 245, 249, 0.4)'
        : device.occupancy_status
        ? 'rgba(43, 88, 117, 0.04)'  
        : 'rgba(241, 245, 249, 0.4)',
      border: `1px solid ${
        !device.is_online
          ? '#e2e8f0'
          : device.occupancy_status
          ?  'rgba(43, 87, 117, 0.7)'  
          : '#e2e8f0'
      }`,

        transition: 'all 0.2s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: (theme) => theme.shadows[1],
        },
        position: 'relative',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: { xs: '0.3rem', sm: '0.5rem' },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '0.9rem', sm: '1.1rem' },
            fontWeight: 700,
            color: device.occupancy_status ? '#1E4D8C' : (theme) => theme.palette.mode === 'dark' ? '#94a3b8' : '#64748b',  // Cambiado aquí
            maxWidth: '80%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {device.device_name}
        </Typography>
        {!device.is_online && (
          <WifiOff
            sx={{
              fontSize: { xs: '1.4rem', sm: '1.7rem' },
              color: 'error.main',
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: { xs: 0.5, sm: 1 },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 0.5, sm: 1 } }}>
          {device.is_online ? (
            device.occupancy_status ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: 'rgba(43, 88, 117, 0.15)',  
                  borderRadius: '50%',
                  padding: '4px',
                  border: '1px solid rgba(43, 87, 117, 0.2)',
                }}
              >
                <Person
                  sx={{
                    color: '#2B5875',  
                    fontSize: { xs: '1.2rem', sm: '1.4rem' },
                    
                  }}
                />
              </Box>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Box>

        {hasOtherLabels && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {filteredLabels.map((label) => (
            <Chip
              key={label.labelId}
              label={label.labelName}
              size="small"
              sx={{
                borderRadius: '20px',
                fontSize: { xs: '0.6rem', sm: '0.7em' },
                border: '1px solid rgba(43, 87, 117, 0.2)',
                backgroundColor: 'rgba(43, 88, 117, 0.1)',
                color: '#2B5875',
              }}
            />
          ))}
        </Box>
        )}
      </Box>
    </Paper>
  );
};

export default DeviceCard;
