import React, { useState } from 'react';
import { useLogin, useNotify, useTranslate } from 'react-admin';
import { 
  Box,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import logo from '../../assets/images/logo512.png';

const LoginContainer = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'linear-gradient(135deg, #f8fafc 0%, #e2e8f0 100%)',
  padding: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const LoginBox = styled(Paper)(({ theme }) => ({
  width: '100%',
  maxWidth: 440,
  borderRadius: '1.5rem',
  overflow: 'hidden',
  backgroundColor: '#ffffff',
  boxShadow: '0 25px 50px -12px rgb(0 0 0 / 0.15)',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '1rem',
    maxWidth: '100%',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '8px',
    background: 'linear-gradient(90deg, #00A650 0%, #2EB96D 100%)',
  }
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(8, 4, 4),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4, 3, 3),
  },
}));

const FormContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 6, 6),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3, 4),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '1rem',
    backgroundColor: '#f8fafc',
    transition: 'all 0.2s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0.75rem',
    },
    '&.Mui-focused': {
      backgroundColor: '#ffffff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#00A650',
        borderWidth: '2px',
      },
    },
    '&:hover': {
      backgroundColor: '#ffffff',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#2EB96D',
      },
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#e2e8f0',
    transition: 'all 0.2s ease-in-out',
  },
  '& .MuiInputBase-input': {
    padding: '1rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.75rem',
      fontSize: '0.875rem',
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '1rem',
  padding: '1rem',
  color: '#FFFFFF',
  fontSize: '1rem',
  textTransform: 'none',
  fontWeight: 600,
  background: '#27963C',
  transition: 'all 0.2s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    borderRadius: '0.75rem',
    padding: '0.75rem',
    fontSize: '0.875rem',
  },
  '&:hover': {
    transform: 'translateY(-1px)',
    boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
    background: '#3fb556'
  },
  '&:active': {
    transform: 'translateY(0)',
  },
}));

const MyLoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const login = useLogin();
  const notify = useNotify();
  const translate = useTranslate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = (e) => {
    e.preventDefault();
    login({ username, password }).catch(() =>
      notify(translate('ra.auth.sign_in_error'), { type: 'error' })
    );
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <LoginContainer>
      <LoginBox>
        <LogoContainer>
          <Box
            component="img"
            src={logo}
            alt="Logo"
            sx={{
              width: 'auto',
              height: isMobile ? 100 : 140,
              mb: isMobile ? 3 : 4,
              filter: 'drop-shadow(0 4px 6px rgb(0 0 0 / 0.1))',
            }}
          />
          <Typography
            variant={isMobile ? "h5" : "h4"}
            sx={{
              fontWeight: 700,
              color: '#1a1a1a',
              mb: 1,
              textAlign: 'center',
            }}
          >
            {translate('pages.login.welcome')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#666666',
              textAlign: 'center',
              fontSize: isMobile ? '0.875rem' : '1rem',
            }}
          >
            {translate(`pages.login.subtitle`)}
          </Typography>
        </LogoContainer>

        <FormContainer>
          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: isMobile ? 2 : 3 
            }}
          >
            <StyledTextField
              name="username"
              placeholder={translate('ra.auth.username')}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineIcon sx={{ 
                      color: '#94a3b8',
                      fontSize: isMobile ? '1.25rem' : '1.5rem'
                    }} />
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              name="password"
              type={showPassword ? 'text' : 'password'}
              placeholder={translate('ra.auth.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockOutlinedIcon sx={{ 
                      color: '#94a3b8',
                      fontSize: isMobile ? '1.25rem' : '1.5rem'
                    }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePassword}
                      edge="end"
                      size={isMobile ? 'small' : 'medium'}
                      sx={{ 
                        color: '#94a3b8',
                        '&:hover': {
                          color: '#64748b'
                        }
                      }}
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            
            <StyledButton
              variant="contained"
              type="submit"
              fullWidth
              sx={{ mt: isMobile ? 1 : 2 }}
            >
              {translate(`pages.login.login_button`)}
            </StyledButton>
          </Box>
        </FormContainer>
      </LoginBox>
    </LoginContainer>
  );
};

export default MyLoginPage;

