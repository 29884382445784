import React from 'react';
import { Notification } from 'react-admin';
import { Alert, Box } from '@mui/material';

const CustomNotification = () => {
    return (
        <Notification
            // Forzar la posición superior
            sx={{
                '& .MuiSnackbar-root': {
                    position: 'fixed',
                    top: '24px !important',
                    left: '50% !important',
                    right: 'auto !important',
                    bottom: 'auto !important',
                    transform: 'translateX(-50%)'
                }
            }}
        >
            {(notification) => (
                <Box sx={{
                    minWidth: '500px',
                    '& .MuiAlert-root': {
                        backgroundColor: '#47a8b5 !important',
                        color: '#fff !important',
                        padding: '16px 24px',
                    },
                    '& .MuiAlert-icon': {
                        fontSize: '28px !important',
                        color: '#fff !important'
                    },
                    '& .MuiAlert-message': {
                        fontSize: '1rem',
                        padding: '4px 0',
                        fontWeight: 500
                    }
                }}>
                    <Alert 
                        severity={notification.type || 'info'}
                        variant="filled"
                    >
                        {notification.message}
                    </Alert>
                </Box>
            )}
        </Notification>
    );
};

export default CustomNotification;