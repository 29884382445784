// CustomDataGrid.js
import React from 'react';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomDataGridDesktop from './CustomDataGridDesktop';
import CustomDataGridMobile from './CustomDataGridMobile';
import { useListContext, useTranslate } from 'react-admin';
import NoDataMessage from './NoDataMessage';
import { DevicesOther } from '@mui/icons-material';


const CustomDataGrid = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { data = [], isLoading } = useListContext();
  const translate = useTranslate();

  const Component = isMobile ? CustomDataGridMobile : CustomDataGridDesktop;


  if (!isLoading && data.length === 0) {
      return (
        <NoDataMessage
          icon={DevicesOther}
          title={translate('pages.dashboard.no_devices')}
          description={translate('pages.dashboard.no_devices_description')}
    />
      );
  }

  return <Component {...props} />;
};


export default CustomDataGrid;





