import React, { useEffect, useState } from 'react';
import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    CircularProgress,
    FormHelperText,
    useTheme,
} from '@mui/material';
import { useDataProvider, useTranslate } from 'react-admin';

const GroupSelector = ({ onChange, selectedGroup }) => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const theme = useTheme();

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            try {
                const { data } = await dataProvider.getList('group', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'group_name', order: 'ASC' },
                    filter: {},
                });
                setGroups(data);
                setError(null);
            } catch (error) {
                console.error('Error fetching groups:', error);
                setError(translate('resources.groups.error_loading'));
            } finally {
                setLoading(false);
            }
        };

        fetchGroups();
    }, [dataProvider, translate]);

    const handleChange = (event) => {
        const selectedId = event.target.value;
        const selectedGroupObject = groups.find((group) => group.id === selectedId) || null;
        onChange(selectedGroupObject);
    };

    const getCurrentValue = () => {
        if (!selectedGroup) return '';
        if (typeof selectedGroup === 'string') return selectedGroup;
        if (typeof selectedGroup === 'number') return selectedGroup;
        if (typeof selectedGroup === 'object' && selectedGroup.id) return selectedGroup.id;
        return '';
    };

    return (
        <FormControl fullWidth error={!!error} size="small">
            <InputLabel id="group-selector-label">
                {translate('resources.groups.name')}
            </InputLabel>
       
            <Select
                labelId="group-selector-label"
                value={getCurrentValue()}
                label={translate('resources.groups.name')}
                onChange={handleChange}
                disabled={loading}
                endAdornment={
                    loading && <CircularProgress size={20} sx={{ mr: theme.spacing(2) }} />
                }
                sx={{
                    '& .MuiSelect-select': {
                      py: 1.5,
                    },
                  }}
            >
                {groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                        {group.group_name}
                    </MenuItem>
                ))}
            </Select>

            {error && <FormHelperText>{error}</FormHelperText>}
            
        </FormControl>
    );
};

export default GroupSelector;
