import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, useTheme, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslate } from 'react-admin';

const CustomCreateButton = ({ resource, CreateComponent, dialogTitle, ...props }) => {
    const translate = useTranslate();
    const [open, setIsOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    return (
        <>
            <Button
                onClick={handleOpen}
                variant="createButton"
                startIcon={<AddIcon />}
            >
                {!isMobile && translate(`resources.${resource}.actions.create`)}
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    sx: {
                        margin: { xs: '16px', sm: '32px' },
                        width: { xs: '100%', sm: 'auto' },
                        maxWidth: { xs: '100%', sm: '800px' },
                        maxHeight: { xs: '100%', sm: '90vh' },
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}
            >
                <DialogTitle>
                    {dialogTitle || translate(`resources.${resource}.create_title`)}
                </DialogTitle>
                <DialogContent>
                    <CreateComponent
                        onCancel={handleClose}
                        onSuccess={handleClose}
                        {...props}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default CustomCreateButton;
