import { defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';


const commonConfig = {
    typography: {
        fontFamily: '"Inter", "Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14, 
        htmlFontSize: 14,
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiButton: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: {
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '12px',
                    fontWeight: 500,
                    borderRadius: '4px',
                    textTransform: 'none',
                    padding: '8px 16px',
                    transition: 'background-color 0.3s ease, color 0.3s ease',
                }
            }
        },
        '& .MuiDialog-paper': ({ theme }) => ({
            borderRadius: '12px',
            padding: '24px',
            minWidth: '400px',
            backgroundColor: theme.palette.background.paper,
            border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.grey[700]}` : 'none',
            boxShadow: theme.palette.mode === 'dark' 
                ? '0 4px 6px -1px rgba(0, 0, 0, 0.5), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
                : '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
        }),
        '& .MuiDialogTitle-root':({ theme }) => ({
            padding: '0 0 16px 0',
            fontSize: '1.25rem',
            fontWeight: 600,
            color: theme.palette.text.primary
        }),
        '& .MuiDialogContent-root': ({ theme }) => ({
            padding: '0 0 24px 0',
            color: theme.palette.text.secondary,
            fontSize: '0.975rem'
        }),
        MuiDialogActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: '6px 16px',
                    fontWeight: 500,
                    minWidth: 'auto',
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: '4px',
                   
                    '& .MuiButton-text': {  
                        backgroundColor: theme.palette.mode === 'light' ? '#f8f9fa' : '#2D3748',
                        color: theme.palette.mode === 'light' ? '#495057' : '#E2E8F0',
                        border: `1px solid ${theme.palette.mode === 'light' ? '#e9ecef' : '#4A5568'}`, // Borde para el botón cancelar
                        '&:hover': {
                            backgroundColor: theme.palette.mode === 'light' ? '#e9ecef' : '#4A5568',
                        }
                    },
        
                    '& .MuiButton-contained': {  
                        backgroundColor: theme.palette.error.main,
                        color: '#FFFFFF',
                        border: `1px solid ${theme.palette.error.main}`, 
                        '&:hover': {
                            backgroundColor: theme.palette.error.dark,
                            borderColor: theme.palette.error.dark, 
                        }
                    },
                    '& .MuiSvgIcon-root': {
                        fontSize: '1.25rem',
                    },
                })
            }
        },
       
        MuiAlert: {
            styleOverrides: {
                root: {
                    '&.MuiAlert-filledWarning': {
                        backgroundColor: '#47a8b5',
                        color: '#fff'
                    },
                    // También puedes definir otros tipos si lo deseas
                    '&.MuiAlert-filledInfo': {
                        backgroundColor: '#47a8b5',
                        color: '#fff'
                    },
                    '&.MuiAlert-filledError': {
                        backgroundColor: '#EF4444',
                        color: '#fff'
                    },
                    '&.MuiAlert-filledSuccess': {
                        backgroundColor: '#10B981',
                        color: '#fff'
                    }
                },
                icon: {
                    opacity: 1
                }
            }
        },

        MuiSnackbar: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.12)',
                        minWidth: '400px',
                        maxWidth: '600px'
                    }
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: '12px', 
                    border: `1px solid ${theme.palette.grey[200]}`,
                    overflow: 'hidden',
                    tableLayout: 'fixed',
                    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.05)',  
        
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.grey[800],
                        fontWeight: 700,
                        padding: '12px 16px',  
                    
                        textAlign: 'left',
                        borderBottom: `2px solid ${theme.palette.grey[300]}`,
                        textTransform: 'uppercase',
                        letterSpacing: '0.1em',  
                        whiteSpace: 'nowrap',
                        position: 'relative',
                        transition: 'all 0.2s ease',
        
                        '&::after': {  
                            content: '""',
                            position: 'absolute',
                            right: 0,
                            top: '25%',
                            height: '50%',
                            width: '1px',
                            backgroundColor: theme.palette.grey[200],
                        },
        
                        '&:last-child::after': {
                            display: 'none'
                        },
        
                        '&:hover': {
                            backgroundColor: theme.palette.grey[100],
                        }
                    },
        
                
                    '& .RaDatagrid-row': {
                        transition: 'background-color 0.2s ease',
                        
                        '&:nth-of-type(odd)': {
                            backgroundColor: theme.palette.background.paper,
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: theme.palette.grey[50],
                        },
                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}08`,  
                        },
                        borderBottom: `1px solid ${theme.palette.grey[100]}`,
                    },
        
                  
                    '& .RaDatagrid-rowCell': {
                        padding: '4px 16px',
                        height: '48px',  
                        textAlign: 'left',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: theme.palette.text.primary,  
                        transition: 'all 0.2s ease',
                        
                        '&:not(:last-child)': {
                            borderRight: `1px solid ${theme.palette.grey[100]}`,  
                        },
                    },
        
                    '& .RaDatagrid-rowCell:first-of-type': {
                        fontWeight: 500,  
                        color: theme.palette.grey[900],
                    },
        
                    '& .MuiTablePagination-root': {
                        borderTop: `1px solid ${theme.palette.grey[200]}`,
                        '& .MuiToolbar-root': {
                            minHeight: '48px',
                            padding: '0 16px',
                        },
                        '& .MuiSelect-select': {
                            padding: '4px 32px 4px 8px',
                        }
                    },
        
                  
                    '& .MuiChip-root': {
                        height: '24px',
                        backgroundColor: theme.palette.grey[200],
                        color: theme.palette.grey[700],
                        marginRight: '4px',
                        transition: 'all 0.2s ease',
                        
                        '&:hover': {
                            backgroundColor: theme.palette.grey[200],
                        }
                    }
                })
            }
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderBottom: '1px solid',
                    borderColor: 'grey.100',
                    borderRadius: 0,
                    height: '56px',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    '&.Mui-focused': {
                        backgroundColor: 'transparent',
                    }
                },
                input: {
                    padding: '16.5px 14px',
                },
            }
        },

        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: 'none', 
                    border: 'none', 
                    marginBottom: theme.spacing(2),
                }),
            },
        },
        
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '12px 16px',
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                }
            }
        },
        ScrollBox: {
            styleOverrides: {
                root: ({ theme }) => ({
                    overflowX: 'auto',
                    width: '100%',
                    '&::-webkit-scrollbar': {
                        height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: theme.palette.grey[100],
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.grey[300],
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: theme.palette.grey[400],
                        }
                    }
                })
            }
        }
    }
};

const createAllButtonVariants = (palette, theme) => ({
    variants: [
        {
            props: { variant: 'createButton' },
            style: {
                backgroundColor: '#47a8b5',
                color: '#FFFFFF',
                boxShadow: 'none',
                padding: '6px 16px',
                fontWeight: 500,
                fontSize: '0.875rem',
                minWidth: 'auto',
                display: 'inline-flex',
                alignItems: 'center',
                gap: '4px',

                '&:hover': {
                    backgroundColor: '#53c3d2',
                },

                '& .MuiSvgIcon-root': {
                    fontSize: '1.25rem',
                    color: '#FFFFFF',
                },

                [`${theme.breakpoints.down('sm')}`]: {
                    position: 'fixed',
                    right: '16px',
                    bottom: '16px',
                    width: '56px',
                    height: '56px',
                    borderRadius: '50%',
                    padding: 0,
                    minWidth: '56px',
                    zIndex: 1300,
                    backgroundColor: '#47a8b5',
                    boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                    justifyContent: 'center',
                    '& .MuiButton-startIcon': {
                        margin: 0,
                    }
                }
            }
        },
    
        {
            props: { variant: 'primary' },
            style: {
                backgroundColor: palette.primary.main,
                color: palette.primary.contrastText,
                '&:hover': {
                    backgroundColor: palette.primary.dark,
                }
            }
        },
        {
            props: { variant: 'secondary' },
            style: {
                backgroundColor: palette.mode === 'light' ? '#f8f9fa' : '#2D3748',
                color: palette.mode === 'light' ? '#495057' : '#E2E8F0',
                border: `1px solid ${palette.mode === 'light' ? '#e9ecef' : '#4A5568'}`,
                '&:hover': {
                    backgroundColor: palette.mode === 'light' ? '#e9ecef' : '#4A5568',
                }
            }
        },
        {
            props: { variant: 'actionButton' },
            style: ({ theme }) => ({
                minWidth: 'auto',
                width: {
                    xs: '40px',
                    sm: '32px',
                },
                height: {
                    xs: '40px',
                    sm: '32px',
                },
               
                
                color: palette.mode === 'light' ? '#495057' : '#E2E8F0',
                border: `1px solid ${palette.mode === 'light' ? '#e9ecef' : '#4A5568'}`,
                backgroundColor: palette.mode === 'light' ? '#f8f9fa' : '#2D3748',
                padding: {
                    xs: theme.spacing(1),
                    sm: theme.spacing(0.5, 1),
                },
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.3s ease, color 0.3s ease',
                
                '&:hover': {
                    backgroundColor: palette.mode === 'light' ? '#e9ecef' : '#4A5568',
                },
                '& .MuiSvgIcon-root': {
                    fontSize: '1.1rem',

                },
                [theme.breakpoints.down('sm')]: {
                    width: '35px',
                    height: '35px',
                    padding: theme.spacing(1),
                },
            }),
        },
        
    ]
});

const lightPalette = {
    mode: 'light',
    primary: {
        main: '#47a8b5',
        light: '#e5eaf2',
        dark: '#53c3d2',
        contrastText: '#FFFFFF',
        color: '#47a8b5'
    },
    secondary: {
        main: '#475569',
        light: '#9ba7bc',
        dark: '#374561',
        contrastText: '#FFFFFF',
        color: '#53c3d2'
    },
    background: {
        default: '#d1d9e6',     
        paper: '#ffffff',   
        dark: '#f3f6fb'
    },
    text: {
        primary: '#0a1221',    
        secondary: '#3b4759',  
    },
    error: {
        main: '#EF4444',
        light: '#F87171',
        dark: '#DC2626',
    },
    warning: {
        main: '#F59E0B',
        light: '#FBBF24',
        dark: '#D97706',
    },
    info: {
        main: '#0EA5E9',
        light: '#38BDF8',
        dark: '#0284C7',
    },
    success: {
        main: '#10B981',
        light: '#34D399',
        dark: '#059669',
    },
    grey: {
        50: '#f9fbfe',         
        100: '#f3f6fb',        
        200: '#e5eaf2',       
        300: '#d1d9e6',        
        400: '#9ba7bc',        
        500: '#6b7a94',        
        600: '#4d5b75',        
        700: '#374561',       
        800: '#1d2844',     
        900: '#0a1221',       
    },
    blue: {
        50: '#F5F9FF',   
        100: '#EDF4FF',  
        200: '#E0EDFF', 
        300: '#C5DCFF',  
        400: '#9DC3FF', 
        500: '#75AAFF',  
        600: '#60A5FA',  
        700: '#4B89DD', 
        800: '#3B6CB0',  
        900: '#2C5183',
    }
};

const darkPalette = {
    mode: 'dark',
    primary: {
        main: '#47a8b5',
        light: '#93C5FD',
        dark: '#2563EB',
        contrastText: '#FFFFFF',
        color: '#47a8b5'
    },
    secondary: {
        main: '#F59E0B',
        light: '#FCD34D',
        dark: '#D97706',
        contrastText: '#FFFFFF',
        color: '#53c3d2'
    },
    background: {
        default: '#0F172A',
        paper: '#1E293B',
    },
    text: {
        primary: '#F8FAFC',
        secondary: '#CBD5E1',
    },
    error: {
        main: '#EF4444',
        light: '#FCA5A5',
        dark: '#DC2626',
    },
    warning: {
        main: '#F59E0B',
        light: '#FCD34D',
        dark: '#D97706',
    },
    info: {
        main: '#0EA5E9',
        light: '#38BDF8',
        dark: '#0284C7',
    },
    success: {
        main: '#10B981',
        light: '#34D399',
        dark: '#059669',
    },
    grey: {
        900: '#F8FAFC', 
        800: '#F1F5F9',
        700: '#E2E8F0', 
        600: '#CBD5E1',
        500: '#94A3B8',
        400: '#64748B', 
        300: '#475569', 
        200: '#334155', 
        100: '#1E293B', 
        50: '#0F172A',
    },
    blue: {
        50: '#E6F3FF',   
        100: '#C7E3FF',  
        200: '#93CBFF', 
        300: '#60A5FA', 
        400: '#4B9EFF',  
        500: '#3B8CF2',  
        600: '#2D7CE6', 
        700: '#1E6FDA',  
        800: '#1660CD',  
        900: '#0E4DAB', 
    }
};


const lightTheme = createTheme({
    ...defaultTheme,
    ...commonConfig,
    palette: lightPalette,
    components: {
        ...commonConfig.components,
        MuiButton: {
            ...commonConfig.components.MuiButton,
            ...createAllButtonVariants(lightPalette, { breakpoints: { down: key => `@media (max-width: ${key === 'sm' ? '600' : '1200'}px)` } })
        },
        MuiIconButton: {
            ...commonConfig.components.MuiIconButton
        },

    },
});

const darkTheme = createTheme({
    ...defaultTheme,
    ...commonConfig,
    palette: darkPalette,
    components: {
        ...commonConfig.components,
        MuiButton: {
            ...commonConfig.components.MuiButton,
            ...createAllButtonVariants(darkPalette, { breakpoints: { down: key => `@media (max-width: ${key === 'sm' ? '600' : '1200'}px)` } })
        },
        RaDatagrid: {
            styleOverrides: {
                root: ({ theme }) => ({
                    
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    border: `1px solid ${theme.palette.grey[700]}`,
                    overflow: 'hidden',
                    
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: theme.palette.grey[400],
                        color: theme.palette.grey[500],
                        fontWeight: 900,
                        padding: '12px 16px',
                        fontSize: '0.95rem',
                        textTransform: 'uppercase',
                        letterSpacing: '0.05em',
                        borderBottom: `2px solid ${theme.palette.grey[700]}`,
                    },

                    '& .RaDatagrid-row': {
                        '&:nth-of-type(odd)': {
                            backgroundColor: theme.palette.grey[200],
                        },
                        '&:nth-of-type(even)': {
                            backgroundColor: theme.palette.background.paper,
                        },
                        '&:hover': {
                            backgroundColor: theme.palette.grey[300],
                        },
                        borderBottom: `1px solid ${theme.palette.grey[500]}`,
                    },

                    '& .RaDatagrid-rowCell': {
                        color: theme.palette.text.primary,
                        padding: '12px 16px',
                    }
                })
            }
        },
        MuiCard: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.grey[800],
                    border: `1px solid ${theme.palette.grey[700]}`,
                    '& .MuiCardHeader-root': {
                        borderBottom: `1px solid ${theme.palette.grey[700]}`,
                    }
                })
            }
        },
        MuiChip: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.grey[500],
                    color: theme.palette.text.primary,
                   
                    '&:hover': {
                        backgroundColor: theme.palette.grey[600],
                    }
                })
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderBottom: `1px solid ${theme.palette.grey[700]}`,
                    color: theme.palette.text.primary,
                })
            }
        },
        MuiTableRow: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&:nth-of-type(odd)': {
                        backgroundColor: theme.palette.grey[800],
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: theme.palette.background.paper,
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.grey[700],
                    }
                })
            }
        },
       
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.grey[400],
                    '&:hover': {
                        backgroundColor: theme.palette.grey[700],
                        color: theme.palette.common.white,
                    }
                })
            }
        },

    }
});

export { lightTheme, darkTheme };