const spanishCustomMessages = {
 
 
  
  buttons:
  {
    search:"Filtrar",
  },

  pages: {

   dashboard:{
      name:"Estado actual",
      occupied: "Ocupada",
      available: "Desocupada",
      no_devices: "Nada que mostrar por ahora",
      no_devices_description: "Añade contenido o ajusta los filtros para continuar",
      select_order_by:{
        label: "Ordenar por",
        option_1: "Nombre",
        option_2: "Ocupación",
        option_3: "Última comunicación",
      } ,
    },
    login:{
      welcome: "Roommatik Eco",
      subtitle:"Inicia sesión para acceder a tu cuenta",
      login_button: "Iniciar sesión"
    },
    SensorDetailsPopover:{
      no_results: "No se han encontrado sensores",
    }
  },
  resources: {
    group: {
      delete_confirmation: "¿Está seguro de que desea eliminar el grupo?",
      delete_title: "Eliminar grupo",
    },
    groups: {
      name: "Grupos",
      edit_title: "Editar grupo",
      create_title: "Crear grupo",
      associated_installations: "Instalaciones",
      fields: {
        id: "ID",
        group_name: "Nombre del Grupo",
        owner_info: "Información del Propietario",
        description: "Descripción",
      },
      actions: {
        delete_conflict: "Elimine primero las instalaciones asociadas a este grupo en la sección Instalaciones",
        installations_loading: "Loading ... ",
        installations_empty: "No hay instalaciones asociadas",
        create: "Crear",
      },
    },
    installations: {
      edit_page_title: "Modificar  instalación",
      create_page_title: "Crear instalación",
      delete_title: "Eliminar instalación",
      delete_confirmation: "¿Está seguro de que desea eliminar la instalación '%{name}'?",
      error_loading_installations: "Error al cargar las instalaciones",
      name: "Instalaciones",
      all: "Todas",
      fields: {
        id: "ID",
        group_name: "Nombre del Grupo",
        installation_name: "Nombre de la Instalación",
        api_key: "Clave API",
        max_devices: "Nº máximo de controladores",
        license_expiration: "Caducidad de licencia",
      },
      actions: {
        change_api_key: "Cambiar API key",
        confirm_change_api_key_title: "Confirmar cambio de API key",
        confirm_change_api_key_text: "¿Está seguro de que desea cambiar la API key para esta instalación?",
        api_key_generated_title: "API key creada",
        api_key_generated_text: "Se ha generado una nueva API key para esta instalación. Cópiela ahora, ya que no se mostrará nuevamente.",
        api_key_copied: "API key copiada al portapapeles",
        api_key_copy_failed: "Error al copiar la API key",
        cancel: "Cancelar",
        confirm: "Confirmar",
        copy: "Copiar",
        close: "Cerrar",
        create: "Crear",
        delete_conflict: "Elimine primero los controladores asociados",
      },
    },
    devices: {
      name: "Controladores",
      all:"Todos",
      error_loading: "Error al cargar los controladores. Por favor, inténtalo de nuevo más tarde.",
      delete_title:"Eliminar controlador",
      delete_confirmation: "Está seguro de que quiere eliminar el controlador '%{name}'?",
      loading: "Cargando",
      select_label: "Etiquetas",
      no_label: "Todas",
      manage_labels: "Etiquetas",
      create_label: "Crear nueva etiqueta",
      delete_label_confirmation:  "Está seguro de que quiere eliminar la etiqueta?",
      fields: {
        id: "ID",
        installation_id: "ID instalación",
        installation_name: "Instal.",
        device_name: "Nombre",
        device_type: "Tipo",
        occupancy_status: " ",
        last_event_time: "Último Evento",
        last_config_time: "Última Conf.",
        modes: "Modos",
        technical_info: "Red",
        times: "Tiempos",
        firmware: "Firmware",
        ip: "IP",
        mac: "MAC",
        ac: "AC",
        power: "Energía",
        inactivity_duration: "Inactividad",
        circuit_disconnect: "Corte de energía",
        ac_disconnect: "AC Apagado",
        window_open: "Ventana abierta",
        labels: "Etiquetas"
      },
      tooltips: {
        firmware: "Versión del firmware del controlador",
        ip: "Dirección IP del controlador",
        mac: "Dirección MAC del controlador",
        ac_mode: "Modo de operación del aire acondicionado",
        power_mode: "Modo de operación de energía",
        inactivity_duration: "Duración de inactividad",
        circuit_disconnect: "Tiempo para desconectar la energía",
        ac_disconnect: "Tiempo para apagar el aire acondicionado",
        window_open: "Retraso al abrir la ventana",
        view_sensors: "Ver sensores"
      },
      values:{
        unknown:"-",
      },
    },
    events: {
      name: "Eventos",
      fields: {
          id: "ID",
          device_id: "ID del controlador",
          device_name: "Controlador",
          event_key: "Clave",
          event_value: "Valor",
          timestamp: "Fecha y hora",
          timestamp_from: "Desde",
          timestamp_to: "Hasta"
      },
      delete_all: "Eliminar todos los eventos",
      delete_installation: "Eliminar eventos de esta instalación",
      delete_device: "Eliminar eventos de este dispositivo",
      delete_confirmation_title: "Confirmación de eliminación",
      delete_confirmation_content: "¿Estás seguro de que deseas eliminar los eventos?",
      error_fetching: "Error al obtener los eventos. Por favor, inténtalo de nuevo.",
       deleting: "Eliminando eventos, espere"
  },
    users: {
      name: "Usuarios",
      edit_page_title: "Editar usuario",
      create_page_title: "Crear un nuevo usuario",
      change_password_message: "La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y símbolos",
      delete_title: "Eliminar usuario",
      delete_confirmation: "¿Está seguro de que quiere eliminar el usuario '%{name}'?",
      password_requirements: "La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas, minúsculas, números y símbolos",
      fields: {
        id: "ID",
        user_name: "Nombre",
        role_name: "Rol",
        active: "Activo",
        email: "Email",
        installations: "Instalaciones",
        associations: "Permisos",
        groups: "Grupos",
        new_password: "Nueva contraseña",
        password: "Contraseña"
      },
      roles:{
        Maint2: "Visor de datos",
        Maint1: "Responsable técnico",
        InstallationManager: "Director de instalación",
        GroupManager:"Gestor de organización",
        Superuser: "Administrador del sistema",
        unknown: "Desconocido"
      },
      actions:{
        change_password: "Cambiar contraseña",
        add: "Añadir",
        password_changed_success:"Contraseña cambiada satisfactoriamente",
        create: "Crear",
      },
      sections:{
        user_info: "Datos de usuario",
        group_management: "Permisos",
        installation_management: "Permisos"
      }
    },
    occupancyAnalytics: {
      name: "Análisis de Ocupación",
      hotelSelector: "Seleccionar Hotel",
      dateRange: "Seleccionar Rango de Fechas",
      generateCharts: "Generar Gráficos",
      lastWeek: "Última Semana",
      lastMonth: "Último Mes",
      customDate: "Rango de Fechas Personalizado",
      occupancyChart: "Gráfico de Ocupación",
      highMovementHours: "Horas de Alto Movimiento",
      trendAnalysis: "Análisis de Tendencia",
      showTable: "Mostrar Tabla de Datos"
    },
    sensors: {
      types: {
        pir: 'Sensor de movimiento',
        door: 'Sensor de puerta',
        window: 'Sensor de ventana',
      },
      subtypes: {
          wireless: 'Inalámbrico',
          wired: 'Cableado',
          Main: 'Cableado'
      }
    },
    
  },
  
};

export default spanishCustomMessages;

