import React, { useState } from 'react';
import { useSetLocale, useLocale } from 'react-admin';
import { 
    IconButton,
    Button, 
    Menu, 
    MenuItem, 
    Typography,
    Box,
    useTheme,
    useMediaQuery
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckIcon from '@mui/icons-material/Check';

const LanguageSwitcher = () => {
    const setLocale = useSetLocale();
    const locale = useLocale();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (lng) => {
        if (lng) {
            setLocale(lng);
            window.dispatchEvent(new Event('languageChanged'));
        }
        setAnchorEl(null);
    };

    return (
        <Box>
            {isSmallScreen ? (
                // Versión móvil - solo icono
                <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'language-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{
                        color: 'text.primary',
                    }}
                >
                    <LanguageIcon />
                </IconButton>
            ) : (
                // Versión desktop - botón completo
                <Button
                    aria-controls={open ? 'language-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={
                        <KeyboardArrowDownIcon 
                            sx={{ 
                                transform: open ? 'rotate(180deg)' : 'rotate(0)',
                                transition: 'transform 0.2s'
                            }} 
                        />
                    }
                    startIcon={<LanguageIcon />}
                    sx={{
                        textTransform: 'none',
                        color: 'text.primary',
                        backgroundColor: 'background.paper',
                        '&:hover': {
                            backgroundColor: 'action.hover',
                        },
                        px: 2,
                        py: 1,
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: 'divider'
                    }}
                >
                    <Typography variant="body2">
                        {locale === 'en' ? 'English' : 'Español'}
                    </Typography>
                </Button>
            )}

            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose(null)}
                PaperProps={{
                    elevation: 2,
                    sx: {
                        mt: 1,
                        minWidth: isSmallScreen ? 140 : 180,
                        '& .MuiMenuItem-root': {
                            py: 1,
                            px: 2,
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            >
                {[
                    { code: 'en', label: 'English' },
                    { code: 'es', label: 'Español' }
                ].map((language) => (
                    <MenuItem
                        key={language.code}
                        onClick={() => handleClose(language.code)}
                        selected={locale === language.code}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: 1
                        }}
                    >
                        <Typography variant="body2">
                            {language.label}
                        </Typography>
                        {locale === language.code && (
                            <CheckIcon fontSize="small" sx={{ color: 'primary.main' }} />
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default LanguageSwitcher;