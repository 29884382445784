import React, { useEffect, useState } from 'react';
import {
    Popover,
    CircularProgress,
    Typography,
    Box,
    TextField,
    Divider,
    useTheme,
} from '@mui/material';
import { useTranslate, useDataProvider, useNotify } from 'react-admin';
import {
    Label,
    Add,
} from '@mui/icons-material';
import { CustomButton } from '../common/CustomButton';
import CustomDeleteButton from '../common/CustomDeleteButton';

const LabelManagementPopover = ({ 
    deviceId,
    deviceName,
    installationId,
    mouseX,
    mouseY,
    onClose,
    onRefresh 
}) => {
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [newLabelName, setNewLabelName] = useState('');
    const [creating, setCreating] = useState(false);
    const translate = useTranslate();
    const theme = useTheme();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const open = mouseX !== null && mouseY !== null;

    useEffect(() => {
        setLabels([]);
    }, [installationId]);

    useEffect(() => {
        const fetchLabels = async () => {
            if (!installationId || !mouseX || !mouseY) return;
            
            setLoading(true);
            try {
                const { data } = await dataProvider.getList('labels', {
                    filter: { installation_id: installationId },
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'labelName', order: 'ASC' },
                });
                setLabels(data);
            } catch (error) {
                console.error('Error fetching labels:', error);
               
            } finally {
                setLoading(false);
            }
        };

        fetchLabels();
    }, [installationId, mouseX, mouseY, dataProvider, notify]);

    const handleCreateLabel = async () => {
        if (!newLabelName.trim()) return;
        
        setCreating(true);
        try {
            await dataProvider.createLabel(newLabelName.trim(), installationId);
            setNewLabelName('');
            notify(translate('resources.labels.created'), { type: 'success' });
            
            // Recargar la lista de labels
            const { data } = await dataProvider.getList('labels', {
                filter: { installation_id: installationId },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'labelName', order: 'ASC' },
            });
            setLabels(data);
        } catch (error) {
            notify(translate('resources.labels.create_error'), { type: 'error' });
        } finally {
            setCreating(false);
        }
    };

    const handleAssignLabel = async (labelId) => {
        try {
            await dataProvider.assignDeviceLabel(deviceId, labelId);
            onRefresh();
            onClose();
            notify('Label assigned successfully', { type: 'success' });
        } catch (error) {
            notify('Error assigning label', { type: 'error' });
        }
    };

    return (
        <Popover
            open={open}
            anchorReference="anchorPosition"
            anchorPosition={open ? { top: mouseY, left: mouseX } : undefined}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            elevation={3}
            sx={{
                '& .MuiPopover-paper': {
                    width: '384px',
                    maxHeight: '400px',
                    overflow: 'hidden',
                    mt: 0.5,
                    borderRadius: 2,
                    bgcolor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                }
            }}
        >
            {loading ? (
                <Box sx={{ p: 3, display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={24} />
                </Box>
            ) : (
                <Box>
                    <Box sx={{ 
                        bgcolor: theme.palette.primary.main,
                        p: 2,
                        borderBottom: `1px solid ${theme.palette.divider}`
                    }}>
                        <Typography 
                            variant="subtitle1" 
                            align="center" 
                            sx={{
                                color: theme.palette.primary.contrastText,
                                fontWeight: 500,
                                letterSpacing: '0.5px'
                            }}
                        >
                            {deviceName}
                        </Typography>
                    </Box>

                    <Box sx={{ p: 2 }}>
                        <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                            <TextField
                                size="small"
                                fullWidth
                                value={newLabelName}
                                onChange={(e) => setNewLabelName(e.target.value)}
                                placeholder={translate('resources.devices.create_label')}
                                disabled={creating}
                            />
                            <CustomButton
                                variant="primary"
                                icon={<Add />}
                                onClick={handleCreateLabel}
                                disabled={!newLabelName.trim() || creating}
                            />
                        </Box>
                        <Divider />
                    </Box>

                    <Box sx={{ 
    maxHeight: '250px', 
    overflow: 'auto',
    bgcolor: theme.palette.background.paper
}}>
    {labels.length > 0 ? 
    labels.map((label) => (
        <Box
            key={label.id}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                transition: 'all 0.2s ease',
                '&:hover': {
                    bgcolor: theme.palette.action.hover,
                },
            }}
        >
            <Box
                onClick={() => handleAssignLabel(label.id)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    cursor: 'pointer',
                    flex: 1,
                }}
            >
                <Label sx={{ color: theme.palette.primary.main }} />
                <Typography>{label.labelName}</Typography>
            </Box>
            <CustomDeleteButton
                record={label}
                resource="labels"
                onClick={() => true}  
                onSuccess={() => {
                    onClose(); 
                    onRefresh(); 
                    notify(translate('resources.labels.deleted'), { type: 'success' });
                }}
                onError={(error) => {
                    notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
                }}
                translateOptions={{ name: label.labelName }}
                sx={{
                    '& .MuiIconButton-root': {
                        p: '4px',
                    },
                }}
            />
        </Box>
    ))
    : 
    <Box sx={{ p: 3, textAlign: 'center', color: theme.palette.text.secondary }}>
        {translate('resources.devices.no_label')}
    </Box>
}
</Box>
                </Box>
            )}
        </Popover>
    );
};

export default LabelManagementPopover;