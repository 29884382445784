import React, { useState } from 'react';
import { SimpleForm, useDataProvider, useTranslate, useNotify, useRefresh } from 'react-admin';
import { Box, Paper, Typography, Grid, Divider } from '@mui/material';
import { CustomTextInput, CustomButton } from '../common/CustomButton';
import { CustomEmailIcon, CustomPasswordIcon, CustomRoleIcon, CustomUserIcon } from '../pages/CustomIcons';
import UserGroupsAndInstallations from './UserGroupsAndInstallations';
import ChangePasswordDialog from './ChangePasswordDialog';
import CustomToolbar from '../common/CustomToolBar';

const UserEditDialog = ({ record, onCancel, onSuccess }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const [userGroups, setUserGroups] = useState(record?.groups || []);
    const [userInstallations, setUserInstallations] = useState(record?.installations || []);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

    const handleSubmit = async (data) => {
        try {
            const updatedData = {
                id: record.id,
                username: data.username,
                email: data.email,
                role_id: record.role_id,
                role_name: record.role_name,
                role_level: record.role_level,
                active: record.active,
                groups: userGroups.map(group => group.group_name || group),
                installations: userInstallations.map(installation => installation.installation_name || installation)
            };
    
            await dataProvider.update('users', {
                id: record.id,
                data: updatedData,
                previousData: record,
            });

            refresh();
            if (onSuccess) {
                onSuccess();
            }
        } catch (error) {
            console.error('Error updating user:', error);
            notify(error.message || 'Error updating user', { type: 'error' });
        }
    };

    return (
        <SimpleForm
            record={record}
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar onCancel={onCancel} />}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} md={record.role_level === 0 ? 12 : 6}>
                    <Paper 
                        elevation={0} 
                        variant="outlined" 
                        sx={{ 
                            margin: record.role_level === 0 ? '0 auto' : '0'
                        }}
                    >
                        {record.role_level > 0 && (
                            <>
                                <Typography variant="h6" gutterBottom>
                                    {translate('resources.users.sections.user_info')}
                                </Typography>
                                <Divider sx={{ mb: 2 }} />
                            </>
                        )}
                        
                        <Box>
                            <CustomTextInput
                                source="role_name"
                                label={translate('resources.users.fields.role_name')}
                                defaultValue={translate(
                                    `resources.users.roles.${record.role_name.replace(/[\s.]+/g, '')}`
                                )}
                                readOnly
                                icon={CustomRoleIcon}
                                fullWidth
                            />
                            
                            <CustomTextInput
                                source="username"
                                label={translate('resources.users.fields.user_name')}
                                icon={CustomUserIcon}
                                fullWidth
                            />
                            
                            <CustomTextInput
                                source="email"
                                label={translate('resources.users.fields.email')}
                                icon={CustomEmailIcon}
                                fullWidth
                            />

                            <CustomButton
                                variant="contained"
                                color="primary"
                                onClick={() => setPasswordDialogOpen(true)}
                                icon={<CustomPasswordIcon />}
                                sx={{ 
                                    width: { xs: '100%', sm: 'auto' }
                                }}
                                label =  {translate('resources.users.actions.change_password')}
                                showLabelOnMobile = {true}
                            >
                               
                            </CustomButton>
                        </Box>
                    </Paper>
                </Grid>

                {record.role_level > 0 && (
                    <Grid item xs={12} md={6}>
                        <UserGroupsAndInstallations
                            roleLevel={record.role_level}
                            initialGroups={record.groups || []}
                            initialInstallations={record.installations || []}
                            onGroupsChange={setUserGroups}
                            onInstallationsChange={setUserInstallations}
                        />
                    </Grid>
                )}
            </Grid>

            <ChangePasswordDialog
                open={passwordDialogOpen}
                onClose={() => setPasswordDialogOpen(false)}
                userId={record.id}
            />
        </SimpleForm>
    );
};

export default UserEditDialog;



