import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, FormHelperText } from '@mui/material';
import { useDataProvider, useTranslate } from 'react-admin';

const ConditionalInstallationsSelector = ({ selectedGroup, onChange, selectedInstallation }) => {
  const [installations, setInstallations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  useEffect(() => {
    if (!selectedGroup) {
      setInstallations([]);
      return;
    }

    const fetchInstallations = async () => {
      setLoading(true);
      try {
        const { data } = await dataProvider.getList('installations', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'installation_name', order: 'ASC' },
          filter: { group_id: selectedGroup },
        });
        setInstallations(data);
        setError(null);
      } catch (error) {
        console.error('Error fetching installations:', error);
        setError(translate('resources.installations.error_loading'));
      } finally {
        setLoading(false);
      }
    };

    fetchInstallations();
  }, [selectedGroup, dataProvider, translate]);

  return (
    <FormControl
      fullWidth
      error={!!error}
      disabled={!selectedGroup}
      size="small"
      sx={{
        width: '100%', // Cambiado
        '& .MuiInputLabel-root': {
          color: '#666666',
        },
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#ffffff',
          borderRadius: '8px',
          width: '100%', // Añadido
          '& fieldset': {
            borderColor: '#e2e8f0',
          },
          '&:hover fieldset': {
            borderColor: '#475569',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#475569',
            borderWidth: '1px',
          },
        },
      }}
    >
      <InputLabel id="conditional-installations-selector-label">
        {translate('resources.installations.name')}
      </InputLabel>
      <Select
        labelId="conditional-installations-selector-label"
        value={selectedInstallation || ''}
        label={translate('resources.installations.name')}
        onChange={(event) =>
          onChange({
            id: event.target.value,
            name: installations.find((inst) => inst.id === event.target.value)?.installation_name || '',
          })
        }
        endAdornment={loading && <CircularProgress size={20} sx={{ mr: 2 }} />}
        sx={{
          width: '100%', // Añadido
          '& .MuiSelect-select': {
            py: 1.5,
          },
        }}
      >
        {installations.map((installation) => (
          <MenuItem key={installation.id} value={installation.id}>
            {installation.installation_name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default ConditionalInstallationsSelector;