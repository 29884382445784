import { ExportButton } from 'react-admin';
import { styled } from '@mui/material/styles';

const CustomExportButton = styled(ExportButton)(({ theme }) => ({
    borderRadius: '4px',
    textTransform: 'none',
    boxShadow: 'none',
    minWidth: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '& .MuiButton-startIcon': {
        color: theme.palette.text.primary,
        marginRight: theme.spacing(0.5),
    },

 
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.5),
        minWidth: '40px',
        height: '40px',
        '& .MuiButton-startIcon': {
            margin: 0,
        },
        '& .MuiButton-label': {
            display: 'none',
        },
    },
}));

export default CustomExportButton;