import React from 'react';
import { Box, Typography } from '@mui/material';
import { DevicesOther } from '@mui/icons-material';

const NoDataMessage = ({ icon: Icon = DevicesOther, title, description }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '400px',
                width: '100%',
                backgroundColor: 'background.paper',
                borderRadius: '8px',
                padding: 4,
                gap: 4,
                mt: 4,
            }}
        >
            <Icon
                sx={{
                    fontSize: 72,
                    color: 'text.secondary',
                    opacity: 0.7,
                }}
            />
            <Box sx={{ textAlign: 'center', maxWidth: 500 }}>
                <Typography
                    variant="h5"
                    sx={{
                        color: 'text.primary',
                        fontWeight: 500,
                        mb: 2,
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                        color: 'text.secondary',
                        lineHeight: 1.6,
                    }}
                >
                    {description}
                </Typography>
            </Box>
        </Box>
    );
};

export default NoDataMessage;
