import React from 'react';
import {
    List,
    useTranslate,
    useNotify,
    useRefresh,
    Title,
    TextField,
    useDataProvider,
} from 'react-admin';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import CustomDataGrid from '../common/CustomDatagrid';
import CustomExportButton from '../common/CustomExportButton';
import CustomDeleteButton from '../common/CustomDeleteButton';
import CustomEditButton from '../common/CustomEditButton';
import CustomCreateButton from '../common/CustomCreateButton';
import GroupEditDialog from './GroupEditDialog';
import GroupCreate from './GroupCreate';

const GroupList = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleDeleteClick = async (record) => {
        try {
            const { data: installations } = await dataProvider.getList('installations', {
                filter: { group_id: record?.id },
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'installation_name', order: 'ASC' },
            });

            if (installations.length > 0) {
                notify(
                    translate('resources.groups.actions.delete_conflict'),
                    { 
                        type: 'warning',
                        messageArgs: { name: record.group_name },
                        autoHideDuration: 5000,
                    }
                );
                return false;
            }

            return true;
        } catch (error) {
            notify(
                error.message || translate('ra.notification.http_error'),
                { 
                    type: 'error',
                    autoHideDuration: 5000,
                }
            );
            return false;
        }
    };

    const GroupActionsField = ({ record }) => {
        if (!record) return null;

        return (
            <Box display="flex" gap={theme.spacing(1)}>
                <CustomEditButton 
                    record={record}
                    resource="group"
                    EditComponent={GroupEditDialog}
                    dialogTitle={translate('resources.groups.edit_title')}
                    onSuccess={() => {
                        refresh();
                    }}
                    maxWidth="sm"
                />
                <CustomDeleteButton
                    record={record}
                    resource="group"
                    onClick={handleDeleteClick}
                    translateOptions={{ name: record.group_name }}
                    onSuccess={() => {
                        refresh();
                    }}
                    onError={(error) => {
                        notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
                    }}
                />
            </Box>
        );
    };

    return (
        <List
            {...props}
            perPage={25}
            title={<Title title={translate('resources.groups.name')} />}
            actions={
                <Box
                    display="flex"
                    gap={theme.spacing(1)}
                    sx={{
                        width: isMobile ? '100%' : 'auto',
                        mt: isMobile ? theme.spacing(2) : 0,
                        mb: theme.spacing(2),
                    }}
                >
                    <CustomCreateButton
                        resource="groups"
                        CreateComponent={GroupCreate}
                        dialogTitle={translate('resources.groups.create_title')}
                    />
                    <CustomExportButton label={translate('ra.action.export')} />
                </Box>
            }
            sx={{
                height: '100%',
                '& .MuiTablePagination-root': {
                    marginTop: 'auto',
                },
            }}
        >
            <Box sx={{ flexGrow: 1 }}>
                <CustomDataGrid>
                    <GroupActionsField label="" sortable={false} />
                    <TextField
                        source="group_name"
                        label={translate('resources.groups.fields.group_name')}
                    />
                    <TextField
                        source="description"
                        label={translate('resources.groups.fields.description')}
                    />
                </CustomDataGrid>
            </Box>
        </List>
    );
};

export default GroupList;
