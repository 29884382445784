import React, { useState, useEffect } from "react";
import { Create, SimpleForm, useDataProvider, useTranslate, required, email, useNotify } from 'react-admin';
import { Box, Grid } from '@mui/material';
import { validatePassword } from "../../utils/validatePassword";
import { CustomTextInput, CustomSelectInput } from '../common/CustomButton';
import { CustomUserIcon, CustomEmailIcon, CustomPasswordIcon, CustomRoleIcon } from '../pages/CustomIcons';
import CustomToolbar from "../common/CustomToolBar";
import UserGroupsAndInstallations from "./UserGroupsAndInstallations";
import { translateRoleByLevel } from "../../utils/roleTranslations";

const UserCreate = ({ onCancel }) => {
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const notify = useNotify();
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [userGroups, setUserGroups] = useState([]);
    const [userInstallations, setUserInstallations] = useState([]);

    useEffect(() => {
        dataProvider.getList('roles', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'role_name', order: 'ASC' },
            filter: {}
        }).then(({ data }) => {
            setRoles(data.map(role => ({ 
                id: role.id, 
                name: translateRoleByLevel(role.level, translate), // Traducir el rol basado en su nivel
                level: role.level 
            })));  
        });
    }, [dataProvider, translate]);
    

    const handleRoleChange = (event) => {
        const roleId = event.target.value;
        const role = roles.find(r => r.id === roleId);
        setSelectedRole(role || null);
        setUserGroups([]);
        setUserInstallations([]);
    };

    const validateForm = (values) => {
        const errors = {};
        if (!values.username) errors.username = translate('ra.validation.required');
        if (!values.email) {
            errors.email = translate('ra.validation.required');
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = translate('ra.validation.email');
        }
        if (!values.password) {
            errors.password = translate('ra.validation.required');
        } else if (!validatePassword(values.password)) {
            errors.password = translate("resources.users.change_password_message");
        }
        if (!values.role) errors.role = translate('ra.validation.required');
        return errors;
    };

    const transform = (data) => ({
        ...data,
        role_id: data.role,
        role_name: selectedRole?.name,
        role_level: selectedRole?.level,
        groups: userGroups?.map(group => group.group_name)?.filter(Boolean) || [],
        installations: userInstallations?.map(installation => installation.installation_name)?.filter(Boolean) || [],
    });

    return (
        <Create 
            transform={transform}
            mutationOptions={{
                onSuccess: () => {
    
                    onCancel();
                },
                onError: (error) => {
                    console.error('Mutation error:', error);
                    notify(error.message, { type: 'error' });
                }
            }}
        >
            <SimpleForm 
                validate={validateForm}
                toolbar={<CustomToolbar onCancel={onCancel} />}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={selectedRole && selectedRole.level > 0 ? 6 : 12}>
                        <Box>
                            <CustomTextInput 
                                source="username" 
                                label={translate("resources.users.fields.user_name")}
                                validate={required()}
                                icon={CustomUserIcon}
                                fullWidth
                            />
                            
                            <CustomTextInput 
                                source="email" 
                                type="email"
                                label={translate("resources.users.fields.email")}
                                validate={[required(), email()]}
                                icon={CustomEmailIcon}
                                fullWidth
                            />
                            
                            <CustomTextInput 
                                source="password" 
                                type="password" 
                                label={translate("resources.users.fields.password")}
                                validate={required()}
                                icon={CustomPasswordIcon}
                                fullWidth
                            />
                            
                            <CustomSelectInput 
                                source="role" 
                                choices={roles}
                                optionText="name" // Mostrar el nombre traducido
                                optionValue="id" 
                                onChange={handleRoleChange}
                                label={translate("resources.users.fields.role_name")}
                                validate={required()}
                                icon={CustomRoleIcon}
                                fullWidth
                            />

                        </Box>
                    </Grid>

                    {selectedRole && selectedRole.level > 0 && (
                        <Grid item xs={12} md={6}>
                            <UserGroupsAndInstallations
                                roleLevel={selectedRole.level}
                                initialGroups={userGroups}        
                                initialInstallations={userInstallations}  
                                onGroupsChange={setUserGroups}
                                onInstallationsChange={setUserInstallations}
                            />
                        </Grid>
                    )}
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;