import React from 'react';
import { Admin, Resource, useTranslate } from 'react-admin';
import { Route } from 'react-router-dom'; 
import NotAuthorized from './components/NotAuthorized';
import authProvider from './data/authProvider';
import CustomLayout from './layouts/CustomLayout';
import Dashboard from './components/pages/Dashboard';
import i18nProvider from './i18nProvider';
import MyLoginPage from './components/pages/MyLoginPage.js';
import { lightTheme, darkTheme } from './styles/Theme.js';

import './styles/App.css';
import dataProvider from './data/dataProvider.js';
import { StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import UserList from './components/UserComponents/UserGridList.js';
import UserEditDialog from './components/UserComponents/UserEditDialog.js';
import UserCreate from './components/UserComponents/UserCreate';

import InstallationList from './components/InstallationComponents/InstallationList';
import InstallationCreate from './components/InstallationComponents/InstallationCreate';
import InstallationEditDialog from './components/InstallationComponents/InstallationEditDialog';

import DeviceList from './components/DeviceComponents/DeviceList';

import EventList from './components/EventsComponents/EventList.js';

import GroupList from './components/Group/GroupList.js';  
import GroupEditDialog from './components/Group/GroupEditDialog.js';
import GroupCreate from './components/Group/GroupCreate.js';
import CustomNotification from './components/common/CustomNotification.js';


const App = () => {
    
    const t = useTranslate();
  

    return (
    <StyledEngineProvider injectFirst>
      
        <CssBaseline />
        <Admin
          
          layout= {CustomLayout} 
          loginPage={MyLoginPage}
          theme={lightTheme}  
          darkTheme={darkTheme}  
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          dashboard={Dashboard}
          notification={CustomNotification}
          requireAuth
          disableTelemetry
          customRoutes={[
            <Route 
              key="not-authorized" 
              path="/not-authorized" 
              component={NotAuthorized} 
              noLayout 
            />           
          ]}
        >
          
          {permissions => [
          
            <Resource 
              key="users" 
              name="users" 
              list={permissions <=2 ? UserList : null} 
              create={permissions <=2 ? UserCreate : null} 
              edit={permissions <=2 ? UserEditDialog : null} 
              options={{ label: t('resources.users.name') }}
            />,

           
           
              <Resource
                key="group"
                name="group"
                list={permissions == 0 ? GroupList : null}
                create={permissions == 0 ? GroupCreate : null} 
                edit={permissions == 0 ? GroupEditDialog : null}
                options={{ label: t('resources.groups.name') }}
              />,
         
          
              <Resource
                key="installations"
                name="installations"
                list={permissions == 0 ? InstallationList : null}
                create={permissions == 0 ? InstallationCreate : null} 
                edit={permissions == 0 ? InstallationEditDialog : null}
                options={{ label: t('resources.installations.name') }}
              />,
          
            <Resource
              key="devices"
              name="devices"
              list={permissions <= 3 ? DeviceList : null}
              options={{ label: t('resources.devices.name') }}
            />,
          
            <Resource
              key="events"
              name="events"
              list={permissions <= 3 ? EventList :null }
              options={{ label: t('resources.events.name') }}
            />,
          ]}
        </Admin>
      
    </StyledEngineProvider>
    )
  };
  
  export default App;