
export const translateRoleByLevel = (roleLevel, translate) => {
    const ROLE_LEVEL_KEYS = {
        0: 'resources.users.roles.Superuser', 
        1: 'resources.users.roles.GroupManager',
        2: 'resources.users.roles.InstallationManager', 
        3: 'resources.users.roles.Maint1',
        4: 'resources.users.roles.Maint2', 
    };

    
    return translate(ROLE_LEVEL_KEYS[roleLevel] || 'resources.users.roles.unknown');
};

