import { TextInput, NumberInput, DateInput, SelectInput } from 'react-admin';
import { styled, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import { Button, useMediaQuery } from '@mui/material';

const inputStyles = {
  '& .MuiFilledInput-root': {
      background: 'transparent',
      border: 'none',
      borderBottom: '1px solid #F8FAFC',
      borderRadius: 0,
      height: '56px',
  },
  '& .MuiInputBase-input': {
      padding: '14px',
  },
  '& .MuiSelect-select': {
      padding: '14px',
  },
};

const withIcon = (Component) => {
    const EnhancedComponent = ({ icon: IconComponent, ...props }) => {
        const theme = useTheme();

        if (Component === StyledDateInput) {
            return <Component {...props} />;
        }

        return (
            <Component
                {...props}
                InputProps={{
                    startAdornment: IconComponent ? (
                        <InputAdornment position="start">
                            <IconComponent style={{ color: theme.palette.secondary.main }} />
                        </InputAdornment>
                    ) : null,
                    ...props.InputProps,
                }}
            />
        );
    };

    return EnhancedComponent;
};

const StyledTextInput = styled(TextInput)(inputStyles);
const StyledNumberInput = styled(NumberInput)(inputStyles);
const StyledDateInput = styled(DateInput)({
    '& .MuiFilledInput-root': {
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid #F8FAFC',
        borderRadius: 0,
        height: '56px', 
        display: 'flex',
        alignItems: 'center', 
    },
    '& .MuiInputBase-input': {
        padding: '16px 14px', 
        height: 'auto', 
        lineHeight: '1.5',
    },
    '& .MuiInputAdornment-root': {
        display: 'none', 
    },
    '& .MuiSvgIcon-root': {
        display: 'none', 
    },
});
const StyledSelectInput = styled(SelectInput)(inputStyles);


const CustomTextInput = withIcon(StyledTextInput);
const CustomNumberInput = withIcon(StyledNumberInput);
const CustomDateInput = withIcon(StyledDateInput);
const CustomSelectInput = withIcon(StyledSelectInput);

const CustomButton = ({ 
    children, 
    icon, 
    label, 
    variant = 'secondary', 
    showLabelOnMobile = false, 
    ...props 
  }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 
  
    return (
      <Button
        {...props}
        startIcon={icon}
        className={`custom-button custom-button--${variant}`}
        sx={{
          borderRadius: '4px',
          textTransform: 'none',
          boxShadow: 'none',
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.divider}`,
          color: theme.palette.text.primary,
          padding: theme.spacing(2),
          minWidth: '35px',
          height: '35px',
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
          '& .MuiButton-startIcon': {
            
            margin: 0
           
          },
  
          // Estilos para dispositivos móviles
          [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.5),
            minWidth: '35px',
            height: '35px',
            '& .MuiButton-startIcon': {
              margin: 0,
            },
          },
        }}
      >
        {(!isMobile || showLabelOnMobile) && label} 
      </Button>
    );
  };
  

  
const CustomSaveButton = (props) => (
    <CustomButton 
        icon={<SaveIcon />} 
        type = "submit" 
        variant="primary" 
        showLabelOnMobile = {true}
        {...props}
    >
      {props.children || 'Save'}
    </CustomButton>
);
  
const CustomCancelButton = (props) => (
    <CustomButton 
        icon={<CancelIcon />} 
        variant="secondary" 
        showLabelOnMobile={true}
        {...props}
    >
        {props.children || 'Cancel'}
    </CustomButton>
);
  

export { 
    CustomTextInput, 
    CustomNumberInput, 
    CustomDateInput, 
    CustomSelectInput, 
    CustomButton,
    CustomSaveButton,
    CustomCancelButton
};
