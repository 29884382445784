import React from 'react';
import { Layout } from 'react-admin';
import CustomAppBar from './CustomAppBar';
import CustomMenu from './CustomMenu';
import { useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';

const MobileScrollBox = styled('div')(({ theme }) => ({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
    WebkitOverflowScrolling: 'touch',
    backgroundColor: theme.palette.background.default,
    '& > *': {
        flex: 1,
        overflow: 'auto',
        height: '100%',
        '-webkit-overflow-scrolling': 'touch',
    }
}));

const CustomLayout = (props) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        
    return (
        <Layout
            {...props}
            appBar={CustomAppBar}
            menu={CustomMenu}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: 'calc(100vh - 95px)', 
                '& .RaLayout-appFrame': {
                    display: 'flex',
                    flex: 1,
                    
                },
                '& .RaLayout-contentWithSidebar': {
                    display: 'flex',
                    flex: 1,
                    padding: {
                        xs: '8px',
                        sm: '24px 24px 24px 16px',
                    },
                    
                    paddingTop: {
                        xs: '8px',
                        sm: '35px'
                    },
                    gap: '16px',
                    height: {
                        xs: 'calc(100vh - 56px)',
                        sm: 'auto'
                    },
                },
                
                '& .RaLayout-content': {
                    flex: 1,
                    padding: 0,
                    backgroundColor: 'transparent',
                    display: 'flex',
                    flexDirection: 'column',
                    ...(isMobile ? {
                        '& > div': {
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '8px',
                            boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                            padding: '8px',
                            '& > *:not(.MuiTablePagination-root)': {
                                flex: 1,
                                overflow: 'hidden',
                            }
                        }
                    } : {
                        
                        minHeight: 0,
                        height: {
                            xs: '100%',
                            sm: 'calc(100vh - 95px)' 
                        },
                        overflow: 'auto',
                        '& > div': {
                            backgroundColor: 'background.paper',
                            borderRadius: {
                                xs: 0,
                                sm: '12px',
                            },
                            padding: {
                                xs: '16px',
                                sm: '24px',
                            },
                            boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            overflow: 'auto',
                            position: 'relative',
                        }
                    })
                },
            }}
        >
            {isMobile ? (
                <MobileScrollBox>
                    {props.children}
                </MobileScrollBox>
            ) : (
                props.children
            )}
        </Layout>
    );
};

export default CustomLayout;
