import React, { useState, useEffect } from 'react';
import { SimpleForm, useTranslate, required, useRefresh } from 'react-admin';
import { Grid } from '@mui/material';
import { CustomTextInput, CustomNumberInput, CustomDateInput } from '../common/CustomButton';
import GroupSelector from '../filters/GroupSelector';
import CustomToolbar from '../common/CustomToolBar';
import dataProvider from '../../data/dataProvider';

const InstallationEditDialog = ({ record, onCancel, onSuccess })  => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const [groupId, setGroupId] = useState('');
    const roleLevel = parseInt(localStorage.getItem('level'), 10);
    
    useEffect(() => {
        if (record) {
            setGroupId(record.group_id || '');
        }
    }, [record]);

    const handleChange = (group) => {
        setGroupId(group?.id || '');
    };

    const handleSubmit = async (data) => {
        try {
            const updatedData = {
                ...data,
                group_id: groupId,
            };
            await dataProvider.update('installations', {
                id: record.id,
                data: updatedData,
                previousData: record,
            });
            refresh();
            if (onSuccess) onSuccess();
        } catch (error) {
            console.error('Error updating installation:', error);
        }
    };

 

    return (
        <SimpleForm
            record={record}  
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar onCancel={onCancel} />}
        >
            <Grid container spacing={3}> 
                <Grid item xs={12}>
                    <CustomTextInput
                        source="installation_name"
                        label={translate("resources.installations.fields.installation_name")}
                        fullWidth
                        validate={required()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomNumberInput
                        source="max_devices"
                        label={translate("resources.installations.fields.max_devices")}
                        fullWidth
                        validate={required()}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GroupSelector
                        selectedGroup={{ id: groupId }}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomDateInput
                        source="license_expiration"
                        label={translate("resources.installations.fields.license_expiration")}
                        fullWidth
                        validate={required()}
                        sx={{marginTop:'35px'}}
                    />
                </Grid>
            </Grid>
        </SimpleForm> 
    );
};    

export default InstallationEditDialog;

