import React, { useState, useEffect } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import { 
    Box, 
    Button, 
    Card, 
    CardContent, 
    Stack, 
    CircularProgress,
    IconButton,
    Collapse,
    useTheme,
    useMediaQuery
} from '@mui/material';
import { 
    Search, 
    ExpandLess, 
    ExpandMore 
} from '@mui/icons-material';
import InstallationsSelectorD from './InstallationSelectorD';
import LabelSelectorD from './LabelSelectorD';
import OrderSelectorD from './OrderSelectorD';


const SearchForm = ({ 
    onSearch, 
    loading,
    collapsible = false,
    orderOptions = []
}) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [isExpanded, setIsExpanded] = useState(true);
    const [installations, setInstallations] = useState([]);
    const [loadingInstallations, setLoadingInstallations] = useState(true);
    const [initialLoadDone, setInitialLoadDone] = useState(false);
    
    const [formState, setFormState] = useState({
        installationId: '',
        labelId: '',
        sortBy: orderOptions.length > 0 ? orderOptions[0].value : 'device_name'
    });


    useEffect(() => {
        const loadInstallations = async () => {
            setLoadingInstallations(true);
            try {
                const { data } = await dataProvider.getList('installations', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'installation_name', order: 'ASC' },
                    filter: {},
                });
                setInstallations(data);
                
                if (data.length > 0) {
                    const firstInstallationId = data[0].id;
                    
                  
                    const { data: labelsData } = await dataProvider.getList('labels', {
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'name', order: 'ASC' },
                        filter: { installation_id: firstInstallationId },
                    });

                 
                    const initialState = {
                        installationId: firstInstallationId,
                        labelId: labelsData.length > 0 ? labelsData[0].id : '',
                        sortBy: orderOptions.length > 0 ? orderOptions[0].value : 'device_name'
                    };

                    setFormState(initialState);
                    
                    
                    if (!initialLoadDone) {
                        onSearch(initialState);
                        setInitialLoadDone(true);
                    }
                }
            } catch (error) {
                console.error('Error loading initial data:', error);
            } finally {
                setLoadingInstallations(false);
            }
        };

        if (!initialLoadDone) {
            loadInstallations();
        }
    }, [dataProvider, initialLoadDone, onSearch, orderOptions]);

   
    useEffect(() => {
        if (collapsible && isMobile) {
            setIsExpanded(false);
        }
    }, [isMobile, collapsible]);

    const handleInstallationChange = (installation) => {
        setFormState(prev => ({
            ...prev,
            installationId: installation.id,
            labelId: '' // Reset label al cambiar instalación
        }));
    };

    const handleLabelChange = (labelId) => {
        setFormState(prev => ({
            ...prev,
            labelId
        }));
    };

    const handleSortChange = (value) => {
        setFormState(prev => ({
            ...prev,
            sortBy: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onSearch(formState);
    };

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <Card sx={{background: 'transparent', border:'none'}}>
            {collapsible && isMobile && (
                <Box
                    sx={{
                        
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 2,
                        py: 1,
                        borderBottom: isExpanded ? 1 : 0,
                        borderColor: 'divider',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Search fontSize="small" color="action" />
                        <Box component="span" sx={{ typography: 'body2', color: 'text.secondary' }}>
                            {installations.find(i => i.id === formState.installationId)?.installation_name || ''}
                        </Box>
                    </Box>
                    <IconButton onClick={toggleExpansion} size="small">
                        {isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </Box>
            )}

            <Collapse in={!collapsible || isExpanded || !isMobile}>
                <CardContent>
                    <form onSubmit={handleSubmit}>
                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                            <InstallationsSelectorD
                                selectedInstallation={formState.installationId}
                                onChange={handleInstallationChange}
                                disabled={loadingInstallations}
                                installations={installations}
                            />

                            <LabelSelectorD
                                installationId={formState.installationId}
                                selectedLabel={formState.labelId}
                                onChange={handleLabelChange}
                                disabled={!formState.installationId}
                            />

                            {orderOptions.length > 0 && (
                                <OrderSelectorD
                                    sortBy={formState.sortBy}
                                    setSortBy={handleSortChange}
                                    disabled={!formState.installationId}
                                    options={orderOptions}
                                    label={translate('pages.dashboard.select_order_by.label')}
                                />
                            )}

                            <Button
                                type="submit"
                                variant="contained"
                                disabled={loading || !formState.installationId}
                                startIcon={loading ? <CircularProgress size={20} /> : <Search />}
                                sx={{ 
                                    minWidth: 120,
                                    alignSelf: { sm: 'flex-end' },
                                    mt: { xs: 1, sm: 0 }
                                }}
                            >
                                {translate('buttons.search')}
                            </Button>
                        </Stack>
                    </form>
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default SearchForm;







/*import React, { useState, useEffect } from 'react';
import { useTranslate, useDataProvider } from 'react-admin';
import { 
    Box, 
    Button,
    Card,
    CardContent,
    Stack,
    CircularProgress,
    IconButton,
    Collapse,
    useTheme,
    useMediaQuery 
} from '@mui/material';

import InstallationsSelectorD from './InstallationSelectorD';
import LabelSelectorD from './LabelSelectorD';
import OrderSelectorD from './OrderSelectorD';
import { Search, ExpandLess, ExpandMore } from '@mui/icons-material';

const SearchForm = ({ onSearch, loading }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const [isExpanded, setIsExpanded] = useState(true);
    const [installations, setInstallations] = useState([]);
    const [labels, setLabels] = useState([]); // Añadimos estado para labels
    const [loadingInstallations, setLoadingInstallations] = useState(true);
    const [initialLoadDone, setInitialLoadDone] = useState(false);
    
    const [formState, setFormState] = useState({
        installationId: '',
        labelId: '',
        sortBy: 'device_name'
    });

    // Efecto para cargar labels cuando cambia la installation
    useEffect(() => {
        const fetchLabels = async () => {
            if (!formState.installationId) {
                setLabels([]);
                return;
            }
            
            try {
                const { data } = await dataProvider.getList('labels', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: { installation_id: formState.installationId },
                });
                setLabels(data);
            } catch (error) {
                console.error('Error fetching labels:', error);
                setLabels([]);
            }
        };

        fetchLabels();
    }, [formState.installationId, dataProvider]);

    useEffect(() => {
        if (isMobile) {
            setIsExpanded(false);
        }
    }, [isMobile]);

    // Cargar instalaciones y hacer la búsqueda inicial
    useEffect(() => {
        // Si ya se hizo la carga inicial, no hacer nada
        if (initialLoadDone) return;

        const initializeForm = async () => {
            setLoadingInstallations(true);
            try {
                // 1. Cargar instalaciones
                const { data: installationsData } = await dataProvider.getList('installations', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'installation_name', order: 'ASC' },
                    filter: {},
                });
                setInstallations(installationsData);

                if (installationsData.length > 0) {
                    const firstInstallationId = installationsData[0].id;

                    // 2. Cargar labels para la primera instalación
                    const { data: labelsData } = await dataProvider.getList('labels', {
                        pagination: { page: 1, perPage: 100 },
                        sort: { field: 'name', order: 'ASC' },
                        filter: { installation_id: firstInstallationId },
                    });

                    // 3. Preparar el estado inicial
                    const initialState = {
                        installationId: firstInstallationId,
                        labelId: labelsData.length > 0 ? labelsData[0].id : '',
                        sortBy: 'device_name'
                    };
                    
                    setFormState(initialState);

                    // 4. Realizar la búsqueda inicial
                    onSearch(initialState);
                }
            } catch (error) {
                console.error('Error initializing form:', error);
            } finally {
                setLoadingInstallations(false);
                setInitialLoadDone(true);
            }
        };

        initializeForm();
    }, [dataProvider, initialLoadDone]); 

    const handleInstallationChange = (installation) => {
        console.log('Installation change:', installation);
        setFormState(prev => ({
            ...prev,
            installationId: installation.id,
            labelId: '' // Reset label al cambiar instalación
        }));
    };

    const handleLabelChange = (labelId) => {
        console.log('Label change:', labelId);
        setFormState(prev => ({
            ...prev,
            labelId
        }));
    };

    const handleSortChange = (value) => {
        console.log('Sort change:', value);
        setFormState(prev => ({
            ...prev,
            sortBy: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Submitting search with:', formState);
        onSearch(formState);
    };

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    
    return (
        <Card
        sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'background.paper',
        }}
    >
        {isMobile && (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 2,
                    py: 1,
                    borderBottom: isExpanded ? 1 : 0,
                    borderColor: 'divider',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Search fontSize="small" color="action" />
                    <Box component="span" sx={{ typography: 'body2', color: 'text.secondary' }}>
                        {installations.find(i => i.id === formState.installationId)?.installation_name || ''}
                        {formState.labelId && ' • '}
                        {formState.labelId && (
                            labels.find(l => l.id === formState.labelId)?.labelName || 
                            labels.find(l => l.id === formState.labelId)?.name || 
                            ''
                        )}
                    </Box>
                </Box>
                <IconButton 
                    onClick={toggleExpansion}
                    size="small"
                >
                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
            </Box>
        )}

        <Collapse in={isExpanded || !isMobile}>
            <CardContent>
                <form onSubmit={handleSubmit}>
                    <Stack 
                        direction={{ xs: 'column', sm: 'row' }} 
                        spacing={2}
                        sx={{
                            '& > *': {
                                minWidth: { xs: '100%', sm: 'auto' }
                            }
                        }}
                    >
                        <InstallationsSelectorD
                            selectedInstallation={formState.installationId}
                            onChange={handleInstallationChange}
                            disabled={loadingInstallations}
                            installations={installations}
                        />

                        <LabelSelectorD
                            installationId={formState.installationId}
                            selectedLabel={formState.labelId}
                            onChange={handleLabelChange}
                            disabled={!formState.installationId}
                        />

                        <OrderSelectorD
                            sortBy={formState.sortBy}
                            setSortBy={handleSortChange}
                            disabled={!formState.installationId}
                            options={[
                                { value: 'device_name', label: translate('pages.dashboard.select_order_by.option_1') },
                                { value: 'occupancy_status', label: translate('pages.dashboard.select_order_by.option_2') },
                                { value: 'stale', label: translate('pages.dashboard.select_order_by.option_3') }
                            ]}
                            label={translate('pages.dashboard.select_order_by.label')}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading || !formState.installationId}
                            startIcon={loading ? <CircularProgress size={20} /> : <Search />}
                            sx={{ 
                                minWidth: 120,
                                alignSelf: { sm: 'flex-end' },
                                mt: { xs: 1, sm: 0 }
                            }}
                        >
                            {translate('buttons.search')}
                        </Button>
                    </Stack>
                </form>
            </CardContent>
        </Collapse>
    </Card>
    );
};

export default SearchForm;*/