import React, { useState, useEffect } from 'react';
import { List, TextField, DateField, useTranslate, useDataProvider } from 'react-admin';
import CustomDataGrid from '../common/CustomDatagrid';
import ToolbarSection from '../filters/ToolbarSection';
import InstallationsSelector from '../filters/InstallationsSelector';
import CustomExportButton from '../common/CustomExportButton';
import DeleteEventsButton from './DeleteEventsButtons';
import ConditionalDeviceSelector from '../filters/ConditionalDeviceSelector';

const EventList = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [selectedInstallationId, setSelectedInstallation] = useState('');
    const [selectedDevice, setSelectedDevice] = useState('');
    const [filters, setFilters] = useState({ installation_id: '' });

    const roleLevel = parseInt(localStorage.getItem('level'), 10); 
    const canDeleteAll = (roleLevel == 0);
    const canDeleteByInstallation = (roleLevel == 0); 
    const canDeleteByDevice = (roleLevel <=3);

    useEffect(() => {
        const fetchInstallations = async () => {
            try {
                const { data } = await dataProvider.getList('installations', {
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'installation_name', order: 'ASC' },
                    filter: {},
                });

                if (data && data.length > 0) {
                    setSelectedInstallation(data[0].id);
                    setFilters({ installation_id: data[0].id });
                }
            } catch (error) {
                console.error(translate('resources.installations.error_fetching'), error);
            }
        };

        fetchInstallations();
    }, [dataProvider, translate]);

    const handleInstallationChange = (installation) => {
        setSelectedInstallation(installation.id);
        setSelectedDevice(''); 
        setFilters({ installation_id: installation.id });
       
    };

    const handleDeviceChange = (device) => {
        setSelectedDevice(device || null); 
        setFilters((prevFilters) => ({
            ...prevFilters,
            device_id: device?.id || undefined,
        }));
    };
    
      
      

    return (
        <List
            {...props}
            actions={
                <ToolbarSection
                    leftControls={
                        <>
                        <InstallationsSelector
                            selectedInstallation={selectedInstallationId}
                            onChange={handleInstallationChange}
                        />
                        <ConditionalDeviceSelector
                            selectedInstallation={selectedInstallationId}
                            selectedDevice={selectedDevice}
                            onChange={(device) => handleDeviceChange(device)}
                        />
                        </>
                    }

                    rightControls={
                        <>
                            {canDeleteByDevice && selectedDevice && (
                                <DeleteEventsButton
                                    type="device"
                                    onConfirm={async () => { 
                                        await dataProvider.delete('events', {
                                            device_id: selectedDevice.id, 
                                        });
                                    }}
                                />
                            )}

                            {canDeleteByInstallation && selectedInstallationId && (
                                <DeleteEventsButton
                                    type="installation"
                                    onConfirm={async () => {
                                        await dataProvider.delete('events', {
                                            installation_id: selectedInstallationId, 
                                        });
                                    }}
                                />
                            )}

                        {canDeleteAll && (
                            <DeleteEventsButton
                                type="all"
                                onConfirm={async () => {
                                    await dataProvider.delete('events', {}); 
                                }}
                            />
                        )}
                        
                        <CustomExportButton label={translate('ra.action.export')} />
                        
                        </>
                    }
                />
            }

            filterDefaultValues={filters}
            sort={{ field: 'timestamp', order: 'DESC' }}
            perPage={50}
        >
            <CustomDataGrid>
                <TextField
                    source="device_name"
                    label={translate('resources.events.fields.device_name')}
                />
                <DateField
                    source="timestamp"
                    label={translate('resources.events.fields.timestamp')}
                    showTime
                />
                <TextField
                    source="event_key"
                    label={translate('resources.events.fields.event_key')}
                />
                <TextField
                    source="event_value"
                    label={translate('resources.events.fields.event_value')}
                />
            </CustomDataGrid>
        </List>
    );
};

export default EventList;
