import React from 'react';
import { SimpleForm, required, useNotify, useDataProvider, useTranslate, useRefresh } from 'react-admin';
import { Box, useTheme } from '@mui/material';
import { CustomTextInput } from '../common/CustomButton';
import { CustomGroupIcon, CustomDescriptionIcon } from '../pages/CustomIcons';
import CustomToolbar from '../common/CustomToolBar';

const GroupCreate = ({ onSuccess, onCancel }) => {
    const notify = useNotify();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const theme = useTheme();

    const handleSave = async (data) => {
        try {
            const groupData = {
                ...data,
                owner_info: " ",
            };

            const result = await dataProvider.create('group', { data: groupData });
            if (result) {
                refresh();
                if (onSuccess) {
                    onSuccess();
                }
            }
        } catch (error) {
            notify(error.message || translate('resources.groups.actions.create_error'), { type: 'error' });
        }
    };

    return (
        <SimpleForm
            onSubmit={handleSave}
            toolbar={<CustomToolbar onCancel={onCancel} />}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing(2),
                }}
            >
                <CustomTextInput
                    source="group_name"
                    label={translate("resources.groups.fields.group_name")}
                    validate={required()}
                    icon={CustomGroupIcon}
                    fullWidth
                />
                <CustomTextInput
                    source="description"
                    label={translate("resources.groups.fields.description")}
                    icon={CustomDescriptionIcon}
                    fullWidth
                />
            </Box>
        </SimpleForm>
    );
};

export default GroupCreate;
