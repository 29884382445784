import React, { useState } from 'react';
import { SimpleForm, useNotify, useDataProvider, useTranslate, required, useRefresh } from 'react-admin';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import CustomToolbar from '../common/CustomToolBar';
import { CustomTextInput, CustomNumberInput, CustomDateInput } from '../common/CustomButton';
import GroupSelector from '../filters/GroupSelector';
import { CustomInstallationIcon, CustomDeviceIcon } from '../pages/CustomIcons';

const InstallationCreate = ({ onSuccess, onCancel }) => {
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const translate = useTranslate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const refresh = useRefresh();
    const [selectedGroup, setSelectedGroup] = useState(null);

    const handleSave = async (data) => {
        try {
            await dataProvider.create('installations', { data: { ...data, group_id: selectedGroup?.id } });
            
            if (onSuccess) {
                onSuccess();
                refresh(); 
            }
        } catch (error) {
            notify(error.message || translate('resources.installations.create_error'), { type: 'error' });
        }
    };

    return (
        <SimpleForm
            onSubmit={handleSave}
            toolbar={<CustomToolbar onCancel={onCancel} />}
            sx={{
                '& .RaSimpleForm-form': {
                    maxWidth: isMobile ? '100%' : '500px',
                    margin: 'auto',
                    padding: theme.spacing(3),
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: theme.shadows[2],
                },
            }}
        >
            <CustomTextInput
                source="installation_name"
                label={translate("resources.installations.fields.installation_name")}
                validate={[required()]}
                fullWidth
                icon={CustomInstallationIcon}
                sx={{ mb: theme.spacing(3) }}
            />

            <Box 
                sx={{ 
                    mb: theme.spacing(3),
                    width: '100%', 
                    '& > *': {   
                        width: '100%'
                    }
                }}
            >
                <GroupSelector
                    selectedGroup={selectedGroup?.id || ''}
                    onChange={(group) => setSelectedGroup(group)}
                />
            </Box>

            <Box
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                gap={theme.spacing(3)}
                width="100%"
            >
                <CustomNumberInput
                    source="max_devices"
                    label={translate("resources.installations.fields.max_devices")}
                    validate={[required()]}
                    fullWidth
                    icon={CustomDeviceIcon}
                />
                
                <CustomDateInput
                    source="license_expiration"
                    label={translate("resources.installations.fields.license_expiration")}
                    validate={[required()]}
                    fullWidth
                   
                />
            </Box>
        </SimpleForm>
    );
};

export default InstallationCreate;


