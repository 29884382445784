import React from 'react';
import { 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  CircularProgress, 
  FormHelperText, 
  useTheme 
} from '@mui/material';


const OrderSelectorD = ({ sortBy, setSortBy, options, label, disabled }) => {
    const theme = useTheme();
  
    return (
      <FormControl
        fullWidth
        size="small"
        sx={{
          minWidth: { xs: '100%', sm: 200, md: 250 },
          maxWidth: 300,
        }}
      >
        <InputLabel id="order-selector-label">{label}</InputLabel>
        <Select
          labelId="order-selector-label"
          value={sortBy || ''}
          label={label}
          onChange={(event) => setSortBy(event.target.value)}
          disabled={disabled}
          sx={{
            '& .MuiSelect-select': {
              py: 1.5,
            },
          }}
        >
          {options.map((option) => (
            <MenuItem 
              key={option.value} 
              value={option.value}
              sx={{
                '&:hover': {
                  backgroundColor: `${theme.palette.primary.main}08`,
                },
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main}14`,
                  '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}20`,
                  },
                },
              }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  export default OrderSelectorD;