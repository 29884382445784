import React from 'react';
import { SimpleForm, useTranslate, required } from 'react-admin';
import { Box, Grid, useTheme } from '@mui/material';
import { CustomTextInput } from '../common/CustomButton';
import { CustomGroupIcon } from '../pages/CustomIcons';
import DescriptionIcon from '@mui/icons-material/Description';
import CustomToolbar from '../common/CustomToolBar';
import dataProvider from '../../data/dataProvider';

const GroupEditDialog = ({ record, onCancel, onSuccess }) => {
    const translate = useTranslate();
    const theme = useTheme();

    const handleSubmit = async (data) => {
        try {
            const updatedData = {
                ...data,
                owner_info: " ",
                description: data.description || " ",
            };
            await dataProvider.update('group', {
                id: record.id,
                data: updatedData,
                previousData: record,
            });
            if (onSuccess) onSuccess();
        } catch (error) {
            console.error('Error updating group:', error);
        }
    };

    return (
        <SimpleForm
            record={record}
            onSubmit={handleSubmit}
            toolbar={<CustomToolbar onCancel={onCancel} />}
        >
            <Grid container spacing={theme.spacing(2)}>
                <Grid item xs={12}>
                    <Box>
                        <CustomTextInput
                            source="group_name"
                            label={translate("resources.groups.fields.group_name")}
                            icon={CustomGroupIcon}
                            fullWidth
                            validate={required()}
                        />
                        <CustomTextInput
                            source="description"
                            label={translate("resources.groups.fields.description")}
                            icon={DescriptionIcon}
                            fullWidth
                        />
                    </Box>
                </Grid>
            </Grid>
        </SimpleForm>
    );
};

export default GroupEditDialog;
