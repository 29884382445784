import React from 'react';
import { Datagrid, FunctionField  } from 'react-admin';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDatagrid = styled(Datagrid)(({ theme }) => ({
  ...theme.components.RaDatagrid.styleOverrides.root({ theme })
}));


const ScrollBox = styled(Box)(({ theme }) => ({
  ...theme.components.ScrollBox.styleOverrides.root({ theme })
}));

const CustomDataGridDesktop = (props) => {
  return (
    <ScrollBox>
      <StyledDatagrid
        {...props}
        bulkActionButtons={false}
        size="small"
        rowClick={false}
        sx={{
          
          '& .RaDatagrid-clickableRow': {
            cursor: 'default' 
          },
          ...props.sx
        }}
      >
        {React.Children.map(props.children, (child) => {
          
          if (child?.props?.source === undefined && 
            child?.props?.label === "" && 
            child?.props?.sortable === false) {
          
            return (
              <FunctionField
                render={(record) =>
                  React.cloneElement(child, { record })
                }
              />
            );
          }
          return child;
        })}
      </StyledDatagrid>
    </ScrollBox>
  );
};


export default CustomDataGridDesktop;
