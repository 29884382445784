import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, CircularProgress, FormHelperText } from '@mui/material';
import { useDataProvider, useTranslate } from 'react-admin';

const ConditionalDeviceSelector = ({ selectedInstallation, onChange, selectedDevice }) => {
  const [devices, setDevices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  useEffect(() => {
    if (!selectedInstallation) {
      setDevices([]);
      return;
    }

    const fetchDevices = async () => {
      setLoading(true);
      try {
        const { data } = await dataProvider.getList('devices', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'device_name', order: 'ASC' },
          filter: { installation_id: selectedInstallation },
        });
        setDevices(data);
        setError(null);
      } catch (error) {
        console.error('Error fetching devices:', error);
        setError(translate('resources.devices.error_loading'));
      } finally {
        setLoading(false);
      }
    };

    fetchDevices();
  }, [selectedInstallation, dataProvider, translate]);

  return (
        <FormControl
            fullWidth
            error={!!error}
            disabled={!selectedInstallation}
            size="small"
            sx={{
                width: '100%',
                '& .MuiInputLabel-root': {
                color: '#666666',
                },
                '& .MuiOutlinedInput-root': {
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                width: '100%',
                '& fieldset': {
                    borderColor: '#e2e8f0',
                },
                '&:hover fieldset': {
                    borderColor: '#475569',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#475569',
                    borderWidth: '1px',
                },
                },
            }}
        >
            <InputLabel id="conditional-device-selector-label">
                {translate('resources.devices.name')}
            </InputLabel>
            <Select
                labelId="conditional-device-selector-label"
                value={selectedDevice?.id || ''} // Siempre usamos el `id` aquí
                label={translate('resources.devices.name')}
                onChange={(event) => {
                    const selectedValue = event.target.value;
                    onChange(
                        selectedValue === 'all'
                            ? null // Representa que no hay filtro
                            : {
                                id: selectedValue,
                                name: devices.find((dev) => dev.id === selectedValue)?.device_name || '',
                            }
                    );
                }}
                endAdornment={loading && <CircularProgress size={20} sx={{ mr: 2 }} />}
                sx={{
                    minWidth: '200px',
                    width: '100%',
                    '& .MuiSelect-select': {
                        py: 1.5,
                    },
                }}
            >

                <MenuItem value="">
                    {translate('resources.devices.all')}
                </MenuItem> 
                
                {devices.map((device) => (
                    <MenuItem key={device.id} value={device.id}>
                    {device.device_name}
                    </MenuItem>
                ))}

        </Select>

      {error && <FormHelperText>{error}</FormHelperText>}

    </FormControl>
  );
};

export default ConditionalDeviceSelector;
