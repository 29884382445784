// CustomEditButton.js
import React, { useState, useCallback } from 'react';
import { useTranslate } from 'react-admin';
import {Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';


const CustomEditButton = ({ record, EditComponent, dialogTitle, onSuccess,  resource, maxWidth = 'md' }) => {
    const translate = useTranslate();
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setIsOpen(false);
      
        if (onSuccess) onSuccess();
       
    }, []);

    return (
        <>
           <Button
                onClick={handleClick}
                title={translate('ra.action.edit')}
                variant="actionButton"
                size="small"
            >
                <EditIcon fontSize="small" />
            </Button>
            
            {isOpen && (
                <Dialog 
                    open={true}
                    onClose={handleClose}
                    fullWidth 
                    maxWidth={maxWidth}
                    keepMounted
                    onClick={(e) => e.stopPropagation()}
                    PaperProps={{
                        sx: {
                            margin: '4px',
                            borderRadius: 1,
                            '& .MuiDialogTitle-root': {
                                padding: {
                                    xs: '8px 12px',
                                    sm: '16px 24px'
                                },
                                fontSize: '1.1rem'
                            },
                            '& .MuiDialogContent-root': {
                                padding: {
                                    xs: '8px 12px 12px',
                                    sm: '16px 24px 24px'
                                },
                                '& .MuiPaper-root': {
                                    padding: {
                                        xs: '8px',
                                        sm: '16px'
                                    }
                                },
                                '& .MuiTextField-root': {
                                    marginBottom: '12px',
                                    '& .MuiInputBase-root': {
                                        fontSize: {
                                            xs: '0.875rem',
                                            sm: '1rem'
                                        }
                                    }
                                },
                                '& .MuiButton-root': {
                                    marginTop: '8px'
                                }
                            }
                        }
                    }}
                >
                    <DialogTitle>
                        {dialogTitle || translate('ra.action.edit')}
                    </DialogTitle>
                    <DialogContent>
                        <EditComponent
                            record={record}
                            resource={resource}
                            onCancel={handleClose}
                            onSuccess={handleClose}
                        />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default CustomEditButton;