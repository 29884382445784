// CustomMenu.js
import React from 'react';
import { Menu, useTranslate } from 'react-admin';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import logo from '../assets/images/roommatik_logo.png';
 import logoSmall from '../assets/images/logo512.png';

import { 
  CustomDeviceIcon, 
  CustomEventIcon, 
  CustomGroupIcon, 
  CustomInstallationIcon, 
  CustomUserIcon 
} from '../components/pages/CustomIcons';
import AnalyticsIcon from '@mui/icons-material/Insights';


const CustomMenu = () => {
  const t = useTranslate();
  const roleLevel = parseInt(localStorage.getItem('level'), 10);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
    sx={{
        height: {
            xs: '100%',
            sm: 'calc(100vh - 95px)' 
        },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.paper',
        borderRadius: {
            xs: 0,
            sm: '12px'
        },
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
        overflow: 'hidden',
        position: 'relative',
        
    
        '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            width: '4px',
            height: '100%',
            background: theme => `linear-gradient(to bottom, ${theme.palette.primary.color}, ${theme.palette.secondary.color})`,
            borderRadius: '12px 0 0 12px',
            opacity: 0.7
        },

      
        '& .RaMenu-main': {
            flex: 1,
            padding: '16px 8px',
        },
        '& .RaMenu-item': {
            borderRadius: '8px',
            margin: '4px 8px',
            color: theme.palette.grey[700],
            transition: 'all 0.2s ease',
            
            '&.RaMenu-active': {
                backgroundColor: '#f0f9ff',
                color: '#0ea5e9',
                fontWeight: 500,
                '& .MuiSvgIcon-root': {
                    color: '#0ea5e9',
                }
            },
            '&:hover': {
                backgroundColor: '#f8fafc',
                color: theme.palette.grey[900],
            }
        },
        '& .MuiSvgIcon-root': {
            color: theme.palette.grey[500],
            transition: 'color 0.2s ease',
        },
        
        // Logo container
        '& .logo-container': {
            padding: '24px',
            borderBottom: '1px solid',
            borderColor: theme.palette.grey[200],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }
    }}
>
    {isSmallScreen && (
        <div className="logo-container">
            <img
                src={logo}
                alt="Roommatik Logo"
                style={{
                    width: 'auto',
                    height: '40px',
                }}
            />
        </div>
    )}

        <Menu>
          <Menu.DashboardItem primaryText={ t('pages.dashboard.name')} />

          {roleLevel <= 2 && (
            <Menu.Item 
              to="/users" 
              primaryText={t('resources.users.name')} 
              leftIcon={<CustomUserIcon />} 
            />
          )}
          {roleLevel ==0  && (
            <Menu.Item 
              to="/group" 
              primaryText={ t('resources.groups.name')} 
              leftIcon={<CustomGroupIcon />} 
            />
          )}
          {roleLevel == 0 && (
            <Menu.Item 
              to="/installations" 
              primaryText={ t('resources.installations.name')} 
              leftIcon={<CustomInstallationIcon />} 
            />
          )}

          {roleLevel <=3 && (
          <Menu.Item 
            to="/devices" 
            primaryText={ t('resources.devices.name')} 
            leftIcon={<CustomDeviceIcon />} 
          />
        )}

          {roleLevel <=3 && (
          <Menu.Item 
            to="/events" 
            primaryText={t('resources.events.name')} 
            leftIcon={<CustomEventIcon />} 
          />
        )}
        </Menu>
     </Box>

    
  );
};

export default CustomMenu;

