import { fetchUtils } from 'react-admin';
import queryString from 'query-string';

const apiUrl = process.env.REACT_APP_API_URL;

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    if (url.endsWith('/login')) {
        options.headers.set('x-api-key', process.env.REACT_APP_API_KEY);
    }
    else
    {
        const token = localStorage.getItem('token');
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const filters = { ...params.filter };

        if (filters.timestamp_gte) {
            filters.timestamp_gte = new Date(filters.timestamp_gte).toISOString().slice(0, -1);
        }
        if (filters.timestamp_lte) {
            filters.timestamp_lte = new Date(filters.timestamp_lte).toISOString().slice(0, -1);
        }

        const query = {
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
            ...params.filter,
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({
            data: json.data,
            total: json.total,
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ 
            data: json.data,
            total: json.total,
        }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;
        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...json },   
        })),

        delete: (resource, params) => {
            let url;
            if (resource === 'events' && params.device_id) {
            
                url = `${apiUrl}/${resource}/device/${params.device_id}`;
            } else if (resource === 'events' && params.installation_id) {
               
                url = `${apiUrl}/${resource}/installation/${params.installation_id}`;
            } else if (resource === 'events' && Object.keys(params).length === 0) {
             
                url = `${apiUrl}/${resource}/all`;
            } else {
                
                url = `${apiUrl}/${resource}/${params.id}`;
            }
            return httpClient(url, {
                method: 'DELETE',
            }).then(({ json }) => ({ data: json }));
        },

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        return httpClient(`${apiUrl}/${resource}?${queryString.stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },
    
    changeApiKey: (resource, id) =>
        httpClient(`${apiUrl}/${resource}/${id}/changeApiKey`, {
            method: 'POST',
        }).then(({ json }) => ({ data: json })),

    changePassword: (userId, newPassword) =>
        httpClient(`${apiUrl}/users/${userId}/changePassword`, {
            method: 'POST',
            body: JSON.stringify({ newPassword }), 
            headers: new Headers({ 'Content-Type': 'application/json' }), 
        }).then(({ json }) => ({ data: json })),

    savePushToken: (params) =>
        httpClient(`${apiUrl}/users/savePushToken`, {
            method: 'POST',
            body: JSON.stringify(params.data),
            headers: new Headers({
                'Content-Type': 'application/json',  
            }),
        }).then(({ json }) => ({ data: json })),

    getOccupancyPeakHours: (hotelId, startDate, endDate) => {
        const params = {
            hotelId: hotelId,
            startDate: startDate,
            endDate: endDate,
        };
        const url = `${apiUrl}/Occupancy/occupancy-peak-hours?${queryString.stringify(params)}`;
        return httpClient(url).then(({ json }) => ({
            data: json.data.map(item => ({
                hour: item.hour,
                occupancy: item.occupancy_count,
            })),
            total: json.total,
        }));
    },    

    assignDeviceLabel: (deviceId, labelId) =>
        httpClient(`${apiUrl}/DeviceLabels/${deviceId}/${labelId}`, { 
            method: 'POST',
        }).then(({ json }) => ({ data: json })),
    
    unassignDeviceLabel: (deviceId, labelId) =>
        httpClient(`${apiUrl}/DeviceLabels/${deviceId}/${labelId}`, { 
            method: 'DELETE',
            body: JSON.stringify({ device_id: deviceId, label_id: labelId }),
        }).then(({ json }) => ({ data: json })),

    createLabel: (labelName, installationId) => {
        const url = `${apiUrl}/labels?labelName=${encodeURIComponent(labelName)}&installation_id=${installationId}`;
        return httpClient(url, {
            method: 'POST',
        }).then(({ json }) => ({ data: json }));
    },  
    
    deleteLabel: (labelId) => {
        const url = `${apiUrl}/labels/${labelId}`;
        return httpClient(url, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json }));
    },
 
};

export default dataProvider;