import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Box, useTheme } from "@mui/material";
import { useDataProvider, useTranslate } from "react-admin";

const LabelSelectorD = ({ installationId, selectedLabel, onChange, disabled }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const theme = useTheme();
    
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(false);
  
    // Cargar labels cuando cambia la installation
    useEffect(() => {
        const fetchLabels = async () => {
            // Limpiar labels cuando no hay installation seleccionada
            if (!installationId) {
                setLabels([]);
                return;
            }
    
            setLoading(true);
            try {
                console.log('Fetching labels for installation:', installationId);
                const { data } = await dataProvider.getList('labels', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: { installation_id: installationId },
                });
                
                const processedLabels = data.map(label => ({
                    id: label.id,
                    name: label.labelName || label.name
                }));
                setLabels(processedLabels);
                
                // Si hay un label seleccionado y no está en la nueva lista, limpiarlo
                if (selectedLabel && !processedLabels.find(l => l.id === selectedLabel)) {
                    onChange('');
                }
            } catch (error) {
                console.error('Error fetching labels:', error);
                setLabels([]);
            } finally {
                setLoading(false);
            }
        };
    
        fetchLabels();
    }, [installationId, dataProvider]); // Quitamos selectedLabel del array de dependencias

    return (
        <FormControl
            fullWidth
            size="small"
            sx={{
                minWidth: { xs: '100%', sm: 200, md: 250 },
                maxWidth: 300,
            }}
        >
            <InputLabel id="label-selector-label">
                {translate('resources.devices.select_label')}
            </InputLabel>
    
            <Select
                labelId="label-selector-label"
                value={selectedLabel || ''}
                label={translate('resources.devices.select_label')}
                onChange={(event) => {
                    console.log('Label selected:', event.target.value);
                    onChange(event.target.value);
                }}
                disabled={disabled || loading}
                endAdornment={loading && (
                    <CircularProgress size={20} sx={{ mr: 2 }} />
                )}
                sx={{
                    '& .MuiSelect-select': {
                        py: 1.5,
                    },
                }}
            >
                {labels && labels.length > 0 && labels.map((label) => (
                    <MenuItem 
                        key={label.id} 
                        value={label.id}
                        sx={{
                            '&:hover': {
                                backgroundColor: `${theme.palette.primary.main}08`,
                            },
                            '&.Mui-selected': {
                                backgroundColor: `${theme.palette.primary.main}14`,
                                '&:hover': {
                                    backgroundColor: `${theme.palette.primary.main}20`,
                                },
                            },
                        }}
                    >
                        {label.name}
                    </MenuItem>
                ))}
                {/* All labels option al final */}
                <MenuItem 
                    value=""
                    sx={{
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        mt: 1,
                        pt: 1,
                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.main}08`,
                        },
                        '&.Mui-selected': {
                            backgroundColor: `${theme.palette.primary.main}14`,
                            '&:hover': {
                                backgroundColor: `${theme.palette.primary.main}20`,
                            },
                        },
                    }}
                >
                    {translate('resources.devices.no_label')}
                </MenuItem>
            </Select>
        </FormControl>
    );
};

export default LabelSelectorD;


