import React, { useState } from 'react';
import { useRefresh, useDelete, useTranslate, Confirm } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import {Button } from '@mui/material';


const CustomDeleteButton = ({ 
    resource, 
    onClick, 
    onSuccess, 
    onError, 
    record,
    translateOptions 
}) => {
    const translate = useTranslate();
    const refresh = useRefresh();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [deleteOne] = useDelete(
        resource,
        { id: record?.id, previousData: record },
        {
            onSuccess: () => {
                refresh();
                if (onSuccess) onSuccess();
            },
            onError: (error) => {
                if (onError) onError(error);
            },
        }
    );

    if (!record?.id) {
        return null;
    }

    const handleDelete = async () => {
        try {
            setIsLoading(true);

            if (onClick) {
                const shouldDelete = await onClick(record);
                if (!shouldDelete) {
                    setIsLoading(false);
                    return;
                }
            }

            await deleteOne();
        } catch (error) {
            console.error('Delete error:', error);
        } finally {
            setOpen(false);
            setIsLoading(false);
        }
    };

    const handleClick = async (e) => {
        e.stopPropagation();

        if (!onClick) {
            setOpen(true);
        } else {
            setIsLoading(true);

            try {
                const shouldDelete = await onClick(record);
                if (shouldDelete) {
                    setOpen(true);
                }
            } catch (error) {
                console.error('Validation error:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <>
           <Button
                onClick={handleClick}
                disabled={isLoading}
                title={translate('ra.action.delete')}
                variant="actionButton"
                size="small"
            >
                <DeleteIcon fontSize="small" />
            </Button>

            <Confirm
                open={open}
                title={translate(`resources.${resource}.delete_title`)}
                content={translate(`resources.${resource}.delete_confirmation`)}
                onConfirm={handleDelete}
                onClose={() => setOpen(false)}
                confirmColor="error"
                translateOptions={translateOptions}
            />
        </>
    );
};

export default CustomDeleteButton;