import React, { useEffect, useState } from 'react';
import { 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  CircularProgress,
  FormHelperText,
  useTheme
} from '@mui/material';
import { useDataProvider, useTranslate } from 'react-admin';

const InstallationsSelector = ({ onChange, selectedInstallation }) => {
  const [installations, setInstallations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const theme = useTheme();

  useEffect(() => {
    const fetchInstallations = async () => {
      setLoading(true);
      try {
        const { data } = await dataProvider.getList('installations', {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'installation_name', order: 'ASC' },
          filter: {},
        });
        setInstallations(data);
        setError(null);
        // Si hay instalaciones, selecciona la primera
        if (data.length > 0) {
          onChange({ id: data[0].id, name: data[0].installation_name });
        }
      } catch (error) {
        console.error('Error fetching installations:', error);
        setError(translate('resources.installations.error_loading_installations'));
      } finally {
        setLoading(false);
      }
    };
    
    fetchInstallations();
  }, [dataProvider]);

  return (
    <FormControl
      fullWidth
      error={!!error}
      size="small"
      sx={{
        minWidth: { xs: '100%', sm: 200, md: 250 },
        maxWidth: 300,
      }}
    >
      <InputLabel id="installations-selector-label">
        {translate('resources.installations.name')}
      </InputLabel>

      <Select
        labelId="installations-selector-label"
        value={selectedInstallation || ''}
        label={translate('resources.installations.name')}
        onChange={(event) => onChange({ 
          id: event.target.value, 
          name: installations.find(inst => inst.id === event.target.value).installation_name 
        })}
        
        disabled={loading}
        endAdornment={loading && (
          <CircularProgress size={20} sx={{ mr: 2 }} />
        )}
        sx={{
          '& .MuiSelect-select': {
            py: 1.5,
          },
        }}
      >
        {installations.map((installation) => (
          <MenuItem 
            key={installation.id} 
            value={installation.id}
            sx={{
              '&:hover': {
                backgroundColor: `${theme.palette.primary.main}08`, // Opacidad 8%
              },
              '&.Mui-selected': {
                backgroundColor: `${theme.palette.primary.main}14`, // Opacidad 14%
                '&:hover': {
                  backgroundColor: `${theme.palette.primary.main}20`, // Opacidad 20%
                },
              },
            }}
          >
            {installation.installation_name}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default InstallationsSelector;