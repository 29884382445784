import React from 'react';
import { AppBar, TitlePortal } from 'react-admin';
import { useMediaQuery, useTheme, Box } from '@mui/material';
import LanguageSwitcher from '../components/filters/LanguageSwitcher';
import MyUserMenu from './MyUserMenu';
import logo from '../assets/images/roommatik_logo.png';
import logoSmall from '../assets/images/logo512.png';

const CustomAppBar = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <AppBar
            userMenu={<MyUserMenu />}
            sx={{
                backgroundColor: theme.palette.background.dark,
                color: theme.palette.text.primary,
                boxShadow: '0 2px 4px rgba(0,0,0,0.08)',
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
                height: {
                    xs: theme.spacing(6.5),
                    sm: theme.spacing(8.5),
                },
                '& .MuiToolbar-root': {
                    minHeight: theme.spacing(7.5),
                    padding: {
                        xs: theme.spacing(0, 1),
                        sm: theme.spacing(0, 2),
                    },
                    ...(isSmallScreen && {
                        gap: theme.spacing(0.5),
                        '& .MuiButtonBase-root': { // Para todos los botones en la toolbar
                            padding: theme.spacing(0.75),
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.25rem',
                            }
                        },
                        '& .RaUserMenu-root': { // Específico para el menú de usuario
                            marginLeft: theme.spacing(0.5),
                        },
                    })
                },
            }}
        >
            {!isSmallScreen && (
                <Box
                    component="a"
                    href="https://www.roommatik.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '95%',
                        pt: theme.spacing(0.5),
                        pb: theme.spacing(0.25),
                        '&:hover': {
                            opacity: 0.9, 
                        },
                    }}
                >
                    <img
                        src={isSmallScreen ? logoSmall : logo}
                        alt="Roommatik Logo"
                        style={{
                            width: 'auto',
                            height: theme.spacing(6.075),
                        }}
                    />
                </Box>
            )}

            <TitlePortal
                sx={{
                    color: theme.palette.text.primary,
                    fontSize: {
                        xs: theme.typography.h6.fontSize,
                        sm: theme.typography.h5.fontSize,
                    },
                    fontWeight: 500, 
                    letterSpacing: '0.02em',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textAlign: 'center',
                    flexGrow: 1,
                }}
            />

            <LanguageSwitcher />
        </AppBar>
    );
};

export default CustomAppBar;