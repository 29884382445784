import React, { useState, useEffect } from 'react';
import { useTranslate, useDataProvider, Title } from 'react-admin';
import { Box, CircularProgress } from '@mui/material';
import { DevicesOther } from '@mui/icons-material';
import SensorDetailsPopover from './SensorDetailsPopover';
import DeviceCard from '../common/DeviceCard';
import NoDataMessage from '../common/NoDataMessage';
import SearchForm from '../filters/SearchForm';

const Dashboard = () => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState(null);
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [sortBy, setSortBy] = useState('device_name');

    const [popoverState, setPopoverState] = useState({
        deviceId: null,
        deviceName: null,
        mouseX: null,
        mouseY: null
    });

    const handleSearch = React.useCallback(async (searchParams) => {
        if (!searchParams.installationId) return;
        
        setLoading(true);
        setDevices([]);
        setSelectedLabel(searchParams.labelId);
        setSortBy(searchParams.sortBy); // Añade esta línea
        
        try {
            const filter = {
                installation_id: searchParams.installationId,
                ...(searchParams.labelId ? { label_id: searchParams.labelId } : {})
            };
            
            const { data } = await dataProvider.getList('devices', {
                filter,
                pagination: { page: 1, perPage: 1000 },
                sort: { field: searchParams.sortBy, order: 'ASC' }
            });
            
            const processedData = data.map(device => ({
                ...device,
                is_online: device.is_online ?? false
            }));
            setDevices(processedData);
            setSearchCriteria(searchParams);
        } catch (error) {
            console.error('Error fetching devices:', error);
        } finally {
            setLoading(false);
        }
    }, [dataProvider]);
    
    const handleSensorClick = React.useCallback((event, deviceId, deviceName) => {
        event.stopPropagation();
        setPopoverState({
            deviceId,
            deviceName,
            mouseX: event.clientX,
            mouseY: event.clientY
        });
    }, []); 
    
    useEffect(() => {
        if (!searchCriteria) return;
    
        const interval = setInterval(() => {
            handleSearch(searchCriteria);
        }, 300000);
    
        return () => clearInterval(interval);
    }, [searchCriteria, handleSearch]);

    const handleClosePopover = () => {
        setPopoverState({
            deviceId: null,
            deviceName: null,
            mouseX: null,
            mouseY: null
        });
    };

    const isStale = (device) => !device.is_online; 

    const sortedDevices = [...devices].sort((a, b) => {
        switch (sortBy) {
            case 'device_name':
                return a.device_name.localeCompare(b.device_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            case 'occupancy_status':
                return (b.occupancy_status || 0) - (a.occupancy_status || 0);
            case 'stale':
                return (a.is_online === b.is_online) ? 0 : a.is_online ? -1 : 1;
            default:
                return 0;
        }
    });

    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            gap: 2,
            p: 2
        }}>
            <Title title={translate('pages.dashboard.name')} />
            
            <SearchForm 
                onSearch={handleSearch}
                loading={loading}
                collapsible={true}
                orderOptions={[
                    { value: 'device_name', label: translate('pages.dashboard.select_order_by.option_1') },
                    { value: 'occupancy_status', label: translate('pages.dashboard.select_order_by.option_2') },
                    { value: 'stale', label: translate('pages.dashboard.select_order_by.option_3') }
                ]}
            />

            <Box sx={{
                flex: 1,
                minHeight: 0,
                overflow: 'auto'
            }}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress size={60} />
                    </Box>
                ) : sortedDevices.length > 0 ? (
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: {
                            xs: 'repeat(auto-fill, minmax(100px, 1fr))',
                            sm: 'repeat(auto-fill, minmax(150px, 1fr))',
                            md: 'repeat(auto-fill, minmax(180px, 1fr))',
                        },
                        gap: { xs: 2, sm: 3 },
                        marginTop: '2px'
                    }}>
                        {sortedDevices.map((device) => (
    <DeviceCard
        key={`${device.id}-${selectedLabel}`}  // Añadimos selectedLabel a la key
        device={device}
        handleSensorClick={handleSensorClick}
        isStale={isStale}
        translate={translate}
        selectedLabel={selectedLabel}
    />
))}
                    </Box>
                ) : (
                    <NoDataMessage
                        icon={DevicesOther}
                        title={translate('pages.dashboard.no_devices')}
                        description={translate('pages.dashboard.no_devices_description')}
                    />
                )}
            </Box>

            {popoverState.mouseX !== null && (
                <SensorDetailsPopover
                    deviceId={popoverState.deviceId}
                    deviceName={popoverState.deviceName}
                    mouseX={popoverState.mouseX}
                    mouseY={popoverState.mouseY}
                    onClose={handleClosePopover}
                />
            )}
        </Box>
    );
};

export default Dashboard;








/*import React, { useState, useEffect } from 'react';
import { useTranslate, useDataProvider, Title } from 'react-admin';
import { Box, CircularProgress } from '@mui/material';
import { DevicesOther } from '@mui/icons-material';
import SensorDetailsPopover from './SensorDetailsPopover';
import DeviceCard from '../common/DeviceCard';
import ToolbarSection from '../filters/ToolbarSection';
import InstallationsSelector from '../filters/InstallationsSelector';
import OrderSelector from '../filters/OrderSelector';
import NoDataMessage from '../common/NoDataMessage';
import LabelSelector from '../filters/LabelSelector';

const Dashboard = () => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [installations, setInstallations] = useState([]);
    const [installationId, setInstallationId] = useState('');
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [intervalTime] = useState(300000);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [selectedDeviceName, setSelectedDeviceName] = useState(null);
    const [mouseX, setMouseX] = useState(null);
    const [mouseY, setMouseY] = useState(null);
    const [sortBy, setSortBy] = useState('device_name');
    const now = new Date();
    const [selectedLabel, setSelectedLabel] = useState(null);

    // Fetch installations
    useEffect(() => {
        const fetchInstallations = async () => {
            setLoading(true);
            try {
                const { data } = await dataProvider.getList('installations', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'installation_name', order: 'ASC' },
                    filter: {},
                });
                setInstallations(data);
                if (data.length > 0 && !installationId) {
                    setInstallationId(data[0].id);
                }
            } catch (error) {
                console.error('Error fetching installations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchInstallations();
    }, [dataProvider, installationId]);

    // Fetch devices
    useEffect(() => {
        const fetchDevices = async () => {
            if (installationId) {
                setLoading(true);
                try {
                    // Construir el filtro incluyendo label_id si está seleccionado
                    const filter = {
                        installation_id: installationId,
                        ...(selectedLabel && selectedLabel !== 0 ? { label_id: selectedLabel } : {})
                    };
    
                    const { data } = await dataProvider.getList('devices', {
                        filter,
                        pagination: { page: 1, perPage: 1000 },
                        sort: { field: 'device_name', order: 'ASC' },
                    });
                  
                    const processedData = data.map(device => ({
                        ...device,
                        is_online: device.is_online ?? false, 
                    }));
                    setDevices(processedData);
                } catch (error) {
                    console.error('Error fetching devices:', error);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        fetchDevices();
        const interval = setInterval(fetchDevices, intervalTime);
        return () => clearInterval(interval);
    }, [installationId, dataProvider, intervalTime, selectedLabel]); // Añadir selectedLabel a las dependencias
    
    const handleInstallationChange = (installation) => {
        setInstallationId(installation.id);
        setSelectedLabel(null); 
    };

    const handleSensorClick = (event, deviceId, deviceName) => {
        event.stopPropagation();
        setMouseX(event.clientX);
        setMouseY(event.clientY);
        setSelectedDeviceId(deviceId);
        setSelectedDeviceName(deviceName);
    };

    const handleClosePopover = () => {
        setMouseX(null);
        setMouseY(null);
        setSelectedDeviceId(null);
        setSelectedDeviceName(null);
    };

    const isStale = (device) => !device?.is_online === false;

    

    const sortedDevices = [...devices].sort((a, b) => {
        switch (sortBy) {
            case 'device_name':
                return a.device_name.localeCompare(b.device_name, undefined, {
                    numeric: true,
                    sensitivity: 'base',
                });
            case 'occupancy_status':
                return (b.occupancy_status || 0) - (a.occupancy_status || 0);
            case 'stale':
                return isStale(a) - isStale(b);
            default:
                return 0;
        }
    });
    

    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            <Title title={translate('pages.dashboard.name')} />

            <ToolbarSection
                leftControls={
                    <>
                        <InstallationsSelector
                            selectedInstallation={installationId}
                            onChange={handleInstallationChange}
                        />

                        <LabelSelector
                            installationId={installationId}
                            selectedLabel={selectedLabel}
                            onChange={setSelectedLabel}
                        />

                        <OrderSelector
                            sortBy={sortBy}
                            setSortBy={setSortBy}
                            options={[
                                { value: 'device_name', label: translate('pages.dashboard.select_order_by.option_1') },
                                { value: 'occupancy_status', label: translate('pages.dashboard.select_order_by.option_2') },
                                { value: 'stale', label: translate('pages.dashboard.select_order_by.option_3') },
                            ]}
                            label={translate('pages.dashboard.select_order_by.label')}
                        />
                    </>
                }
            />

            <Box
                sx={{
                    flex: 1,
                    minHeight: 0,
                    overflow: 'auto',
                    p: { xs: 1, sm: 2, md: 3 },
                }}
            >
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                        <CircularProgress size={60} />
                    </Box>
                ) : sortedDevices.length > 0 ? (
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: 'repeat(auto-fill, minmax(100px, 1fr))',
                                sm: 'repeat(auto-fill, minmax(150px, 1fr))',
                                md: 'repeat(auto-fill, minmax(180px, 1fr))',
                            },
                            gap: { xs: 2, sm: 3 },
                            width: '100%',
                        }}
                    >
                        {sortedDevices.map((device) => (
                            <DeviceCard
                                key={device.id}
                                device={device}
                                handleSensorClick={handleSensorClick}
                                isStale={isStale}
                                translate={translate}
                            />
                        ))}
                    </Box>
                ) : (
                    <NoDataMessage
                        icon={DevicesOther}
                        title={translate('pages.dashboard.no_devices')}
                        description={translate('pages.dashboard.no_devices_description')}
                    />
                )}
            </Box>

            {mouseX !== null && mouseY !== null && (
                <SensorDetailsPopover
                    deviceId={selectedDeviceId}
                    deviceName={selectedDeviceName}
                    mouseX={mouseX}
                    mouseY={mouseY}
                    onClose={handleClosePopover}
                />
            )}
        </Box>
    );
};

export default Dashboard;*/

