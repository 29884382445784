import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography } from '@mui/material';
import { useTranslate, useDataProvider } from 'react-admin';
import { validatePassword } from '../../utils/validatePassword';
import CustomToolbar from '../common/CustomToolBar';

const ChangePasswordDialog = ({ open, onClose, userId }) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [newPassword, setNewPassword] = useState('');
    const [passwordChanged, setPasswordChanged] = useState(false);

    const handlePasswordChange = async () => {
        if (newPassword) {
            if (!validatePassword(newPassword)) {
                alert(translate("resources.users.change_password_message"));
                return;
            }
            try {
                await dataProvider.changePassword(userId, newPassword);
                setPasswordChanged(true);
                onClose();
            } catch (error) {
                console.error("Error changing password:", error);
            }
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{translate("resources.users.actions.change_password")}</DialogTitle>
            <DialogContent>
                <TextField
                    label={translate("resources.users.fields.new_password")}
                    type="password"
                    fullWidth
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    sx={{ marginTop: 2, marginBottom: 2 }}
                />
                <Typography variant="body2" sx={{ textAlign: 'justify' }}>
                    {translate("resources.users.change_password_message")}
                </Typography>
            </DialogContent>

            <DialogActions>
            <CustomToolbar onCancel={onClose} onSave = {handlePasswordChange} /> 
               
            </DialogActions>
        </Dialog>
    );
};

export default ChangePasswordDialog;
