import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import Memory from '@mui/icons-material/Memory';
import DomainIcon from '@mui/icons-material/Domain';
import ForwardToInbox from '@mui/icons-material/ForwardToInbox';
import EmailIcon from '@mui/icons-material/Email';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import DescriptionIcon from '@mui/icons-material/Description';



export const CustomInstallationIcon = HomeIcon;
export const CustomUserIcon = PeopleIcon;
export const CustomDeviceIcon = Memory;
export const CustomGroupIcon = DomainIcon;
export const CustomEventIcon = ForwardToInbox;
export const CustomEmailIcon = EmailIcon;
export const CustomPasswordIcon = VpnKeyIcon;
export const CustomRoleIcon = AdminPanelSettingsIcon;
export const CustomDescriptionIcon = DescriptionIcon;