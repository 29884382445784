import React, { useState } from 'react';
import {
    List,
    TextField,
    NumberField,
    DateField,
    useTranslate,
    useNotify,
    useRefresh,
    useDataProvider,
    useGetIdentity
} from 'react-admin';
import { 
    Box, 
    useTheme,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, 
    useMediaQuery
} from '@mui/material';
import { Save, VpnKey } from '@mui/icons-material';
import CustomDataGrid from '../common/CustomDatagrid';
import CustomCreateButton from '../common/CustomCreateButton';
import CustomExportButton from '../common/CustomExportButton';
import CustomEditButton from '../common/CustomEditButton';
import CustomDeleteButton from '../common/CustomDeleteButton';
import { CustomButton } from '../common/CustomButton';
import InstallationCreate from './InstallationCreate';
import InstallationEditDialog from './InstallationEditDialog';
import ApiKeyDialog from './ApiKeyDialog';
import CustomToolbar from '../common/CustomToolBar';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';


const InstallationList = (props) => {
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const theme = useTheme();
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [newApiKey, setNewApiKey] = useState('');
    const roleLevel = parseInt(localStorage.getItem('level'), 10);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   

    const handleDeleteClick = async (record) => {
        try {
            const { data: devices } = await dataProvider.getList('devices', {
                filter: { installation_id: record?.id },
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'device_name', order: 'ASC' },
            });

            if (devices.length > 0) {
                notify(
                    translate('resources.installations.actions.delete_conflict'),
                    { type: 'warning' }
                );
                return false;
            }

            return true;
        } catch (error) {
            notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
            return false;
        }
    };

    const InstallationActionsField = ({ record }) => {
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        
        if (!record) return null;

        const handleConfirmChangeApiKey = async () => {
            if (!record?.id) {
                console.error('No installation ID');
              
                return;
            }
    
            try {
                const { data } = await dataProvider.changeApiKey('installations', record.id);
                setNewApiKey(data.apiKey);
                setConfirmDialogOpen(false);
             
            } catch (error) {
                console.error("Error changing API Key:", error);
                notify(translate('resources.installations.notifications.api_key_error'), {
                    type: 'error'
                });
            }
        };
    
        const handleCloseDialog = () => {
            setNewApiKey('');
        };
    
        return (
            <Box display="flex" gap={theme.spacing(1)}>
                {roleLevel === 0 && (
                    <CustomButton
                        onClick={() => setConfirmDialogOpen(true)}
                        size="small"
                        variant="actionButton"
                        icon =  <VpnKey fontSize="small" />
                    />

                )}

                <CustomEditButton
                    record={record}
                    resource="installations"
                    EditComponent={InstallationEditDialog}
                    dialogTitle={translate('resources.installations.edit_page_title')}
                    maxWidth="sm"
                />

                <CustomDeleteButton
                    record={record}
                    resource="installations"
                    onClick={handleDeleteClick}
                    translateOptions={{ name: record.installation_name }}
                    onSuccess={() => {
                        refresh();
                    }}
                    onError={(error) => {
                        notify(error.message || translate('ra.notification.http_error'), { type: 'error' });
                    }}
                />

                <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                    <DialogTitle>
                        {translate("resources.installations.actions.confirm_change_api_key_title")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate("resources.installations.actions.confirm_change_api_key_text")}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    
                    <CustomToolbar
                        buttons={[
                            <CustomButton
                                key="custom1"
                                variant="secondary"
                                icon={<CancelIcon />}
                                label = {translate("resources.installations.actions.cancel")}
                                onClick={() => setConfirmDialogOpen(false)}
                                showLabelOnMobile = {true}
                            />,
                            <CustomButton
                                key="custom2"
                                variant="primary"
                                icon={<CheckCircle/>}
                                label = {translate("resources.installations.actions.confirm")}
                                onClick={handleConfirmChangeApiKey}
                                showLabelOnMobile = {true}
                            />,
                        ]}
                        /> 
                    </DialogActions>
                </Dialog>

                <ApiKeyDialog 
                    apiKey={newApiKey} 
                    open={!!newApiKey} 
                    onClose={handleCloseDialog} 
                />


            </Box>
        );
    };


    return (
        <List
            {...props}
            perPage={25}
            title={translate("resources.installations.name")}
            actions={
                <Box
                    display="flex"
                    gap={theme.spacing(1)}
                    sx={{
                        width: isMobile ? '100%' : 'auto',
                        mt: theme.spacing(isMobile ? 1 : 0),
                        mb: theme.spacing(isMobile ? 1 : 2),
                        '& .MuiButton-root': {
                            minHeight: theme.spacing(isMobile ? 4 : 5),
                            fontSize: theme.typography.body2.fontSize,
                        },
                    }}
                >
                    <CustomCreateButton
                        resource="installations"
                        CreateComponent={InstallationCreate}
                        dialogTitle={translate('resources.installations.create_title')}
                      
                    />
                    <CustomExportButton label={translate('ra.action.export')} />
                </Box>
            }
            sx={{
                height: '100%',
                '& .MuiTablePagination-root': {
                    marginTop: 'auto',
                },
            }}
        >
            <CustomDataGrid>
                <InstallationActionsField label="" sortable={false} />
                <TextField source="installation_name" label={translate("resources.installations.fields.installation_name")} />
                <TextField source="group_name" label={translate("resources.installations.fields.group_name")} />
                <NumberField source="max_devices" label={translate("resources.installations.fields.max_devices")} />
                {roleLevel === 0 && (<DateField source="license_expiration" label={translate("resources.installations.fields.license_expiration")} />)}
            </CustomDataGrid>
        </List>
    );
};

export default InstallationList;




